import React, { Component, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'
import { LookupHelper } from 'utils/LookupHelper'
import { useNavigate, useLocation } from "react-router-dom";
import { getHumanFriendlyDateString } from 'utils/DateHelper'
import { DataHelper } from 'utils/DataHelper';
import { db } from 'utils/db'


export function UserRow(props) {


    let navigate = useNavigate();

    let user = JSON.parse(props.item.contents);

    function showUser() {
        navigate("/useredit", { state: { item: user } });
    }

    function userStatus() {
        if (user.status == 'active')
            return (<><i className="bi bi-check-circle"></i></>)

        return (<><i className="bi bi-x-octagon"></i></>)

    }

    function rowStatus(status) {
        if(status =='active')
            return "row pointer-col orbit-row";
        else
            return "row pointer-col orbit-row inactive";
    }


    async function deleteUser() {
        user.deleted = 1;
        await DataHelper.upload(user, db.users, user.userId, "user");
    }


    function userLastLogin() {
        return (<span style={{ fontSize: "10px" }}>{getHumanFriendlyDateString(user.lastLogin)} </span> )
    }

    return (
        
        <div className={rowStatus(user.status)}>
            <div className="col-11" onClick={() => { showUser() }} style={{paddingTop: "6px"} }>
                <span className="badge bg-light" style={{ minWidth: "80px" }}> {user.role}</span> {userStatus()} {user.fullName} {userLastLogin()}  
            </div>
            <div className="col-1">
                <button onClick={() => deleteUser()} className="product-delete btn btn-delete btn-light"><i className="bi bi-trash"></i></button>
            </div>
        </div>
    );
}


