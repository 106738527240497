import * as React from 'react';
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { useNavigate } from "react-router-dom";
import { db } from 'utils/db'
import { getHumanFriendlyDateString } from 'utils/DateHelper'


export function TimelineRow(props) {

    let navigate = useNavigate();

    let Timeline = JSON.parse(props.item.contents);

    function TimelineEdit () {
        navigate("/Timelineedit", { state: { item: Timeline } });
    }

    async function deleteTimeline() {
        Timeline.deleted = 1;
        await DataHelper.upload(Timeline, db.timelines, Timeline.timelineId, "timeline");
    }


    return (
        <div className="row listing-row" key={Timeline.tableGuid}>
            <div className="col-11" >
                <div className="listing-subhead"> <i className="bi bi-calendar calendar-color"></i> {getHumanFriendlyDateString(Timeline.timelineDate)} <br /> <i className="bi bi-person-circle"></i> {Timeline.required} </div>
                <div className="listing-title" onClick={() => TimelineEdit()} >{Timeline.title} </div>
            </div>
            <div className="col-1">
                <button onClick={() => deleteTimeline()} className="listing-delete btn btn-delete btn-light"><i className="bi bi-trash"></i></button>
            </div>
        </div>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/