import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import PageSpinner from 'components/ui/spinner/spinner'

import { SaveDraftTemplate, GetDraftTemplate } from 'utils/LocalStorageHelper'

export function TemplateEdit() {


    let navigate = useNavigate();

    const location = useLocation();
    const editor = useRef(null);
    const [content, setContent] = useState();

    const [contents, setContents] = useState(GetDraftTemplate());
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [status, setStatus] = useState("");
    const [isSaving, setIsSaving] = useState(false);


    const config = {
        readonly: false,
        height: 600
    };


    useEffect(() => {

        if (!location.state) {
            if (GetDraftTemplate().length > 0) {
                showToast("Restoring Draft..")
                setContent(GetDraftTemplate())
            }
        }
        else {
            setContent(location.state.template.contents);
            setTitle(location.state.template.title);
            setAuthor(location.state.template.author);
            setStatus(location.state.template.status);
        }

    }, []);

    if (!content) return (<><PageSpinner/></>)

    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const contentUpdate = (value) => {
        if (value != "<p><br></p>")
            SaveDraftTemplate(value);
    };


    const saveTemplate = async () => {

        setIsSaving(true);
        let template = { Contents: GetDraftTemplate(), Title: title, Author: author, Status: status }

        //-- if this is an update - send the Id
        if (location.state && location.state.template.contractTemplateId) {
            template.ContractTemplateId = location.state.template.contractTemplateId;
        }

        const result = await DataHelper.uploadContractTemplate(template);

        if (result) {
            SaveDraftTemplate(""); //-- clear the defaults...
            navigate("/templates")
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - check you are online");
        }

    }

    function CancelButton() {
        SaveDraftTemplate(""); //-- clear the defaults...
        navigate("/templates")

    }


    const updateTitle = (value) => { setTitle(value); };
    const updateAuthor = (value) => { setAuthor(value); };
    const updateStatus = event => { setStatus(event.target.value);};


    return (
        <>
                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <ToastContainer />
<Container>
        <div className="page-header-section anim-fade ">
            <PageHeader pageName={"Contract Templates"} showTime={false} />
            <div className="row">
                <div className="col-3">
                        <label className="visually-hidden" >Template Name</label>
                        <input type="text" value={title} className="form-control" id="autoSizingInput" onChange={(e) => updateTitle(e.target.value)  } placeholder="Template Name" />
                </div>
                <div className="col-3">
                    <label className="visually-hidden" >Username</label>
                    <div className="input-group">
                        <div className="input-group-text">@</div>
                            <input type="text" value={author} className="form-control" id="autoSizingInputGroup" placeholder="Author" onChange={(e) => updateAuthor(e.target.value)} />
                    </div>
                </div>
                <div className="col-2">
                    <label className="visually-hidden" >Preference</label>
                        <select className="form-select" id="autoSizingSelect" onChange={updateStatus}>
                            <option key={1} value="D" selected>Draft</option>
                            <option key={2} value="L">Submit to Legal</option>
                            <option key={3} value="A">Approved</option>
                            <option key={4} value="X">Rejected</option>
                    </select>
                </div>
                    <div className="col-1">
                        <button disabled={isSaving} tooltip="save" className="btn btn-primary" onClick={() => saveTemplate()} ><i className="bi bi-check-lg"></i></button>
                </div>
                <div className="col-1">
                        <button className="btn btn-light" alt="preview"><i className="bi bi-eye"></i></button>
                </div>
                    <div className="col-1" onClick={() => CancelButton() }>
                        <button className="btn btn-dark" alt="cancel editing"><i className="bi bi-x-circle"></i></button>
                </div>
                </div>
                {isSaving && <PageSpinner /> }

            <div className="row" style={{ marginTop: "40px" }}>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                        onChange={contentUpdate}
                />
            </div>

                </div>
            </Container>
        </>

    );

}


