import React, { Component } from 'react';
import LoginControl from './../components/form/LoginControl';
import logo from './../images/orbit-dark.png';

export class Login extends Component
{
    render() {
        return (
            <div id="pageHolder">
                <div id="pageContent">
                    <main role="main">
                        <LoginControl />
                    </main>
                </div>
            </div>
        );
    }
    
}

