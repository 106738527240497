import React, { Component } from 'react';
import WelcomeBanner from 'components/form/WelcomeBanner';

export class Home extends Component {
  static displayName = Home.name;

    render() {

      return (
          <div id="pageHolder">
              <div id="pageContent">
                  <main role="main">
                      <WelcomeBanner />
                  </main>
              </div>
          </div>
    );
  }
}
