import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { UserRow } from 'components/ui/rows/UserRow'
import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'
import { useNavigate } from "react-router-dom";



export function UserListing() {

    let navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');
    const [filterInput, setFilterInput] = useState('');

    const [filteredResults, setFilteredResults] = useState([]);

    const users = useLiveQuery(() => db.users.where("deleted").equals(0).toArray());

    if (!users) return <><PageSpinner /></>;

    const searchItems = (searchValue, filter = "-") => {
        setSearchInput(searchValue)
        const filteredData = [];

        if (filter == "-") filter = filterInput;
        if (filter == "*") filter = "";

        for (let i = 0; i < users.length; i++) {
            const item = JSON.parse(users[i].contents);
            const user = users[i];

            if (searchValue == "" || item.title.toLowerCase().includes(searchValue.toLowerCase())) {
                if (user.deleted == 0) {
                    filteredData.push(user);
                }
            }
        }
        setFilteredResults(filteredData)

    }

    function addNewUser() {
        navigate("/useredit");
    }

    return (
        <Container>
        <motion.div
                className="page-header-section" 
        >

            <PageHeader pageName={"Users"} showTime={true} />


                <div className="row" style={{ marginBottom: "16px" }} >
                    <div className="input-group-filter col-md-6">
                        <input type="text" className="form-control" placeholder="Search for..." aria-label="Filter" aria-describedby="basic-addon1" onChange={(e) => searchItems(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                        <div className="btn btn-primary" onClick={() => addNewUser()}><i className="bi bi-plus-lg"></i>Add</div>
                    </div>
                </div>

                <div className="row">
                </div>


                <div className="row" key={10} >

                    {searchInput.length > 0 || filterInput.length > 0 ? (

                        filteredResults.map(item => (
                            <UserRow item={item} key={item.id} />
                        ))

                    ) : (
                        users.map(item => (
                            <UserRow item={item} key={item.id} />
                        ))
                    )
                    }


                </div>
            </motion.div>
        </Container>
    );

}



