import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ExhibitorRow } from 'components/ui/rows/ExhibitorRow'
import { ExhibitorRowFilter } from 'components/ui/rows/ExhibitorRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'
import ExhibitorModal from 'components/modal/ExhibitorModal'
import { setScrollOffset, getScrollOffset } from 'utils/UXHelper'



export function ExhibitorListing() {

    const [loaded, setLoaded] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedExhibitor, setSelectedExhibitor] = useState({});

    function toggleModal() {
        setShowModal(!showModal);
    }

    function dialogShow(exhibitor) {
        setSelectedExhibitor(exhibitor);
        setShowModal(true);
    }


    useEffect(() => {

        const scrollY = getScrollOffset("exhibitor");

        function resetScroll(y) {
            window.scrollTo({ top: y, left: 0, behavior: "instant" });
            setLoaded(true);
        }

        setTimeout(() => resetScroll(scrollY), 150);


    }, []);

    const exhibitors = useLiveQuery(() => db.exhibitors.orderBy('title').toArray());

    if (!exhibitors) return <><PageSpinner /></>;


    const saveScroll = () => {
        setScrollOffset("exhibitor", window.pageYOffset);
    };



    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const filteredData = [];

        if (searchValue !== '') {
            for (let i = 0; i < exhibitors.length; i++) {
                const item = JSON.parse(exhibitors[i].contents);
                const exhibitor = exhibitors[i];

                if (item.exhibitorName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.standNumber.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                    filteredData.push(exhibitor);
                }

            }
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults([])
        }

    }

    async function cancelContract(tableGuid)
    {

        for (let i = 0; i < exhibitors.length; i++) {
            const item = JSON.parse(exhibitors[i].contents);
            if (item.tableGuid == tableGuid) {
                item.rebookStatus = 'C';
                let result = await DataHelper.upload(item, db.exhibitors, item.exhibitorId, "exhibitor");
            }
        }

        const contracts = await db.contracts.toArray();
        for (let i = 0; i < contracts.length; i++) {
            const item = JSON.parse(contracts[i].contents);
            if (item.tableGuid == tableGuid) {
                item.status = 'C';
                let result = await DataHelper.upload(item, db.contracts, item.tableGuid, "contract");
            }

        }


    }


    return (
        <Container>
        <motion.div
                className="page-header-section" 
        >

            <PageHeader pageName={"Reception"} showTime={true} />


                {!loaded &&
                    <div className="listing-overlay">
                    </div>
                }
                <div className="row" style={{ marginBottom: "16px" }} >
            <div className="input-group-filter col-md-12">
                <input type="text" className="form-control" placeholder="Search for..." aria-label="Filter" aria-describedby="basic-addon1" onChange={(e) => searchItems(e.target.value)} />
                </div>
                </div>
            {searchInput.length > 0 ? (

                filteredResults.map(item => (
                    <ExhibitorRowFilter item={item} key={item.id} dialogShow={dialogShow} />
                ))

            ) : (
                    exhibitors.map(item => (
                        <ExhibitorRow item={item} key={item.id} dialogShow={dialogShow} saveScroll={saveScroll} />
                    ))
            )
            }
                <ExhibitorModal onClose={toggleModal} key={100} show={showModal} exhibitor={selectedExhibitor} cancelContract={cancelContract}>
                </ExhibitorModal>


            </motion.div>
        </Container>
    );

}



