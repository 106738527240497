import React, { Component, useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ProductRow } from 'components/ui/rows/ProductRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'
import { Container } from 'react-bootstrap'


export function ProductListing() {

    let navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');
    const [filterInput, setFilterInput] = useState('');

    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setFilteredResults(products);
    }, []);

    const products = useLiveQuery(() => db.products.where("deleted").equals(0).toArray());


    if (!products) return null;

    function addNewProduct (){
         navigate("/productedit");
    }


    const filterProducts = (selected) => {
        setFilterInput(selected.target.value);
        const filter = selected.target.value;
        searchItems(searchInput, filter);
    }


    const searchItems = (searchValue, filter = "-") => {
        setSearchInput(searchValue)
        const filteredData = [];

        if (filter == "-") filter = filterInput;
        if (filter == "*") filter = "";


        for (let i = 0; i < products.length; i++) {
            const item = JSON.parse(products[i].contents);
            const product = products[i];

            if (searchValue == "" || item.title.toLowerCase().includes(searchValue.toLowerCase()))
            {
                if (filter == "" || filter.toLowerCase() == item.productType.toLowerCase()) {

                    if (product.deleted == 0) {
                        filteredData.push(product);
                    }
                }
            }
        }
        setFilteredResults(filteredData)
 
    }

    return (
        <Container>
            <motion.div className="page-header-section"
        >
           <PageHeader pageName={"Products"} showTime={false} />
                <div className="productSearch">
                <div className="row">
                    <div className="input-group-filter col-md-6">
                        <input type="text" className="form-control" placeholder="Search for..." aria-label="Filter" aria-describedby="basic-addon1" onChange={(e) => searchItems(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                        <label className="visually-hidden" >Preference</label>
                        <select className="form-select" id="autoSizingSelect" value={ filterInput } onChange={filterProducts}>
                            <option key={1} value="*">All Types</option>
                            <option key={2} value="SPACE">SPACE</option>
                            <option key={3} value="DIGITAL">DIGITAL</option>
                            <option key={4} value="DISCOUNT">DISCOUNT</option>
                            <option key={5} value="EVENT">EVENT</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <div className="btn btn-primary" onClick={() => addNewProduct() }><i className="bi bi-plus-lg"></i>Add</div>
                    </div>
                </div>
            </div>


            {searchInput.length > 0 || filterInput.length > 0 ?  (

                    filteredResults.map(item => (
                        <ProductRow item={item} key={item.id} />
                    ))

                ) : (
                    products.map(item => (
                        <ProductRow item={item} key={item.id} />
                    ))
                )
                }
            
            </motion.div>
        </Container>
    );

}


