import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Booth from 'images/booth.png'
import Design from 'images/design.png'
import Carousel from 'react-bootstrap/Carousel';
import orbitWorld from 'images/orbit-grey.png'
import { Modal, Button } from 'react-bootstrap'
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Bruno } from 'pages/Bruno'
import metadata from 'metadata.json';
import { RemoveLocal, ReadLocal } from 'utils/LocalStorageHelper'
import { roleClassName } from 'utils/UXHelper'


function FooterMenu() {

    const navigate = useNavigate();

    const login = ReadLocal(global.config.LOGIN_KEY);
    function linkTo(path) {
        navigate(path);
    }

    return (
        <div>
        {login &&
                <div className = "row orbit-footer">
                    <div className="col-3 orbit-footer-icon" onClick={()=>linkTo("dashboard") } > <i className="bi bi-house-door"></i></div >
                    <div className="col-3 orbit-footer-icon" onClick={() => linkTo("reception")}><i className={roleClassName("bi bi-calendar", "reception,admin")}></i></div>
                    <div className="col-3 orbit-footer-icon" onClick={() => linkTo("exhibitorlisting")}><i className={roleClassName("bi bi-person-badge", "manager,admin")}></i></div>
                    <div className="col-3 orbit-footer-icon" onClick={() => linkTo("productlisting")}><i className={roleClassName("bi bi-file-earmark-pdf", "manager,admin")}></i></div>
                </div >
            }
        </div>
    )
}

export default FooterMenu;
