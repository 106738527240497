import React, { Component, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { db } from 'utils/db'
import { LookupHelper } from 'utils/LookupHelper'
import _ from 'lodash';
import { getHumanFriendlyDateString } from 'utils/DateHelper'


export function TimelineControl(props) {

    const timelines = props.timelines;

    if (!timelines) return null;

    function getSortedTimelines() {

        if (!timelines) return;

            let sorted = [];

            for (let i = 0; i < timelines.length; i++) {
                let timeline = JSON.parse(timelines[i].contents);
                sorted.push(timeline);
            }

            const listing = _.orderBy(sorted, ['timelineDate'], ['asc']);

            return listing;
        }

    const sortedTimelines = getSortedTimelines();

    function whenInTime(entry) {

        const now = new Date();
        const dated = new Date(entry.timelineDate);
        const duration = entry.duration ?? 0;
        const endDated = dated.getTime() + (duration * 60000);

        let value = "";

        if (dated < now) value = "past";
        if (now >= dated && now <= endDated) value = "now";
        if (dated > now) value = "future";

        return value;

    }


    return (
        <>
            {sortedTimelines.map(item =>
                <div className={"row timeline-" + whenInTime(item)} >
                    <div className="col-4">
                        {(whenInTime(item) === 'now') ? <><i className="bi bi-caret-right-fill"></i> now - </> : <></>}
                        { getHumanFriendlyDateString(item.timelineDate)}
                    </div>
                    <div className="col-8">
                        {item.title}
                    </div>
                </div>

            )}
        </>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

 <i className="bi bi-caret-right-fill"></i> now 

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/