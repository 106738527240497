import React, { Component, useState } from 'react';
import { LoggedIn, ReadLocal, WriteLocal, GetLastSyncDate, CreateGUID } from 'utils/LocalStorageHelper'
import xmlData from 'data/ec23.xml';
import XMLParser from 'react-xml-parser';
import axios from 'axios'

class PlanHelper {

    async readFloorplan() {

        let data = null;

        await axios.get(xmlData, {
            "Content-Type": "application/xml; charset=utf-8"
        }).then((response) => {
                let floorplan = new XMLParser().parseFromString(response.data);
                data = floorplan.children[0].children;
        }).catch(error => { return null });

        return data;

    }

}
export const FloorPlanHelper = new PlanHelper();


