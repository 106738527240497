import React, { useEffect, useState } from 'react'
import ec from 'images/ec.png'

function PageHeader(props) {

    const pageName = props.pageName;
    const showTime = props.showTime;

    const time = new Date();
    const timeString = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    const [timeDisplay, setTimeDisplay] = useState(timeString);


    useEffect(() => {
        const interval = setInterval(() => {

            const time = new Date();
            const timeString = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            setTimeDisplay(timeString);
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    function DisplayTime() {
        if (showTime) {
            return (
                <div className="showTime">{timeDisplay}</div>
            )
        }
        else { 
            return (
                <></>
                )
        }
    }

    return (
        <>
        </>

    );

}

export default PageHeader;

/*
 
         <>
            <div className="mobile-heading">
                <div className="row">
                    <div className="col-md-8">
                        <img src={ec} className="showLogo" />
                        <h2 className="showName">Enterprise Connect 2023</h2>
                        <h4 className="pageName">{pageName}</h4>
                        {DisplayTime()}
                    </div>
                </div>
            </div>
        <section>
            <div className="fixed-page-heading">
                <div className="container">
                <div className="row" >
                    <div className="col-md-8">
                            <img src={ec} className="showLogo" />
                            <h2 className="showName">Enterprise Connect 2023</h2>
                            <h4 className="pageName">{pageName}</h4>
                            {DisplayTime() }
                    </div>
                </div>
                </div>
            </div>

            </section>
        </>
 * 
 */ 