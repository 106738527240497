import 'config.js'

export function UserInitials(fullname) {
    if (!fullname) return "";

    const tokens = fullname.split(' ');
    if (tokens.length > 0) {
        if (tokens.length > 1) {

            return tokens[0].charAt(0) + tokens[tokens.length - 1].charAt(0);

        }
        else {
            return tokens[0].charAt(0);
        }
    }

    return "";

}

export function WriteLocal(key, value) {
    localStorage.setItem(key, value);
}

export function RemoveLocal(key, value) {
    localStorage.removeItem(key, value);
}

export function RemoveLocalAll() {
    localStorage.clear();
}


export function WriteLastSyncDate() {

    localStorage.setItem(global.config.LAST_SYNC, new Date().toUTCString());
}

export function SaveDraftTemplate(value) {
    localStorage.setItem(global.config.DRAFT_TEMPLATE, value);
}

export function GetDraftTemplate() {
    let value = "";
    try {
        value = localStorage.getItem(global.config.DRAFT_TEMPLATE);
    }
    catch (err) {
        //-- this draft may not exist - so not really an error? :|
    }
    return value;

}

export function GetLastSyncDate() {

    let value = null;
    try {
        value = localStorage.getItem(global.config.LAST_SYNC);
    }
    catch (err) {
        console.log(err);
    }
    return value;

}

export function ReadLocal(key) {

    let value = null;
    try {
        value = localStorage.getItem(key);
    }
    catch (err) {
        console.log(err);
    }
    return value;
}

export function LoggedIn() {

    let login = null;
    let data = ReadLocal(global.config.LOGIN_KEY);
    if (data) {
        try {
            login = JSON.parse(data);
        }
        catch (err) {
            console.log("login parse object failed..");
        }
    }
    return login;

}

export function CreateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    }).toString();
}