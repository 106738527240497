import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';


import { SaveDraftTemplate, GetDraftTemplate } from 'utils/LocalStorageHelper'

export function ProductEdit() {

    let navigate = useNavigate();

    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    const [product, setProduct] = useState({});


    useEffect(() => {
        if (!location.state) {
            setProduct({ title: '', isLocked: false, productType: "SPACE", hasQuantity: false, isManual: false });
        }
        else {
            const editProduct = _.cloneDeep(location.state.item);
            setProduct(editProduct);
        }
        
    }, []);

    if (product == null) return;



    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const saveProduct = async () => {

        setIsSaving(true);
        let item = product 

        //-- if this is an update - send the Id
        //if (location.state && location.state.item.product) {
        //    item.productId = product.productId;
        //}

        const result = await DataHelper.upload(item, db.products, item.productId, "product");

        if (result) {
            navigate("/productlisting")
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - this looks like an app fault :(");
        }

    }

    function CancelButton() {
        navigate("/productlisting")
    }


    const updateTitle = (e) => { setProduct({ ...product, title: e.target.value }); };
    const updateDescription = (e) => { setProduct({ ...product, description: e.target.value }); };
    const updateHasQuantity = (e) => { setProduct({ ...product, hasQuantity: e.target.checked }); };
    const updatePricingAuto = (e) => { setProduct({ ...product, isManual: false }); };
    const updatePricingManual = (e) => { setProduct({ ...product, isManual: true }); };
    const updateisLocked = (e) => { setProduct({ ...product, isLocked: e.target.checked }); };
    const updateisPreloaded = (e) => { setProduct({ ...product, isPreloaded: e.target.checked }); };
    const updateProductType = (e) => { setProduct({ ...product, productType: e.target.value }); };
    const updateQuantity = (e) => { setProduct({ ...product, quantity: e.target.value }); };
    const updateSortOrder = (e) => { setProduct({ ...product, sortOrder: e.target.value }); };
    const updateEditableCostDefault = (e) => { setProduct({ ...product, editableCostDefault: e.target.value }); };

    const updateUnitPrice = (e) => { setProduct({ ...product, unitPrice: e.target.value }); };
    const updatePostShowUnitPrice = (e) => { setProduct({ ...product, postShowUnitPrice: e.target.value }); };
    const updatePricingFormula = (e) => { setProduct({ ...product, unitFormula: e.target.value }); };
    const updateMinCost = (e) => { setProduct({ ...product, minCost: e.target.value }); };
    const updateMaxCost = (e) => { setProduct({ ...product, maxCost: e.target.value }); };
    const updateValidityFormula = (e) => { setProduct({ ...product, validityFormula: e.target.value }); };
    const updateAuthorisationFormula = (e) => { setProduct({ ...product, authorisationFormula: e.target.value }); };






    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <Container>
            <div className="page-header-section ">
                <PageHeader pageName={"Contract Templates"} showTime={false} />

                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-2" onClick={() => CancelButton()}>
                        <button className="btn btn-light" alt="cancel editing"><i className="bi bi-arrow-left-circle"></i> cancel</button>
                    </div>
                    <div className="col-2">
                        <button disabled={isSaving} tooltip="save" className="btn btn-primary" onClick={() => saveProduct()} ><i className="bi bi-check-lg"></i> save</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-12" >

                <div style={{ border: "1px solid #eee", marginTop: "12px", background: "#fafafa", borderRadius: "10px" }}>
                            <div style={{ padding: "10px" }}>
                <h5>Product Details</h5>

                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-9 col-md-8 col-xl-6">
                                        <input placeholder="Product title e.g. Space Only (Gold) 10x20 " value={product.title} onChange={(e) => updateTitle(e)} className="form-control"></input>
                                        </div>



                    <div className="form-check form-switch col-3 col-md-4 col-xl-2" style={{ marginTop: "6px" }} >
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={product.isLocked} onChange={(e) => updateisLocked(e) } />
                        <label className="form-check-label" >Locked</label>
                                        </div>

                                    </div>

                                {product.isLocked ?
                                    <div className="row" style={{ margin: "12px 0px" }}>
                                        <p><i className="bi bi-info-circle"></i> &nbsp;<b>locked</b> Locked products cannot be removed from a contract <i>and cannot be removed</i></p>
                                    </div>
                                    :
                                    <div style={{ marginBottom: "20px"} }></div>
                                }

                                    <div className="row">
                                        <div className="col-12 col-xl-12" style={{ marginBottom: "40px" } }>
                                            <label>Description</label>
                                            <input placeholder="e.g. On site floorspace for the duration of the event. With access to exhibitor and marketing services" value={product.description} onChange={(e) => updateDescription(e)} className="form-control"></input>
                                        </div>
                                    </div>

                <div className="row">
                    <div className="col-6 col-xl-2">
                                        <label>Product Type</label>
                                        <select className="form-select" id="autoSizingSelect" onChange={(e) => updateProductType(e)} value={ product.productType }>
                            <option key={1} value="SPACE" selected>SPACE</option>
                            <option key={2} value="EVENT">EVENT</option>
                            <option key={3} value="DIGITAL">DIGITAL</option>
                            <option key={4} value="DISCOUNT">DISCOUNT</option>
                        </select>
                    </div>
                    <div className="col-6 col-xl-2">
                        <label>Stock Quantity</label>
                        <div>
                            <input className="form-check-input" type="checkbox" id="checkboxNoLabel" style={{ fontSize: "26px", marginLeft: "30px" }} onChange={(e) => updateHasQuantity(e)} checked={product.hasQuantity} aria-label="..." />
                        </div>
                    </div>
                </div>
                {product.hasQuantity == true ?
                    <div>
                        <div className="row" style={{ margin: "12px 0px 0px 0px" }}>
                            <p> <i className="bi bi-info-circle"></i> &nbsp;<b>has Quantity:</b> Please enter the initial or starting quantity of items you wish to sell. (not the remaining amount - as this is calculated) </p>
                        </div>
                        <div className="row">
                                            <div className="col-5 col-xl-2">
                                                <label>Initial Quantity</label>
                                <div>
                                                    <input placeholder="Stock quantity e.g. 10" className="form-control" value={product.quantity} onChange={(e) => updateQuantity(e) } ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                                    }

                                    <div className="row" style={{marginTop:"20px"} }>
                                        <div className="col-6 col-xl-2">
                                            <label>Sort Order</label>
                                            <div>
                                                <input placeholder="blank/con position" className="form-control" value={product.sortOrder} onChange={(e) => updateSortOrder(e)} ></input>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                               
                            </div>



                        </div>



                </div>


                <div className="row" style={{marginTop: "20px"} }>
                    <div className="col-xl-6 col-12">

                        <div style={{ border: "1px solid #eee", margin: "12px", background: "#fafafa", borderRadius: "10px" }}>
                            <div style={{ padding: "10px" }}>

                            <div className="row" style={{ marginTop: "12px"}}>

                                <h5>Pricing Details</h5>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-12">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => updatePricingAuto(e)} checked={!product.isManual} />
                                            <label className="form-check-label" >
                                                Price Calculated
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => updatePricingManual(e)} checked={product.isManual} />
                                            <label className="form-check-label" >
                                                Price Entered Manually
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                    {product.isManual == true ?
                                        <div>
                                    <div className="row" style={{ marginTop: "14px" }}>
                                        <div className="col-6 col-xl-5">
                                            <div>
                                                <label>Default Value</label>
                                                    <input placeholder="Default price" className="form-control" value={product.editableCostDefault} onChange={(e) => updateEditableCostDefault(e) } ></input>
                                            </div>
                                        </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "20px" }}>
                                                <div className="col-6 col-xl-5" >
                                                    <div>
                                                        <label>Min Price*</label>
                                                        <input placeholder="e.g. 1000" className="form-control" value={product.minCost} onChange={(e) => { updateMinCost(e) }}  ></input>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xl-5">
                                                    <div>
                                                        <label>Maximum Price*</label>
                                                        <input placeholder="eg 50000" className="form-control" value={product.maxCost} onChange={(e) => { updateMaxCost(e) }}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                    :
                                    <div>

                                        <div className="row" style={{ marginTop: "14px" }}>
                                            <div className="col-6 col-xl-5">
                                                <div>
                                                    <label>Unit Price</label>
                                                        <input placeholder="Unit price" className="form-control" value={product.unitPrice} onChange={(e) => { updateUnitPrice(e) }}  ></input>
                                                </div>
                                            </div>
                                            <div className="col-6 col-xl-5">
                                                <div>
                                                    <label>Post Show Price</label>
                                                        <input placeholder="Post show Unit price" className="form-control" value={product.postShowUnitPrice} onChange={(e) => { updatePostShowUnitPrice(e) }}  ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="row" >
                                            <div className="col-12 col-xl-12">
                                                <div>
                                                    <label>Unit Pricing Formula</label>
                                                    <input placeholder="Unit pricing formula" className="form-control" value={product.unitFormula} onChange={(e) => { updatePricingFormula(e) }} ></input>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                                <div className="row" style={{ marginTop: "14px", marginBottom: "24px" }}>
                                    <div className="col-12 col-xl-12">
                                        <p>* leave blank if there is no minimum or maximum price. </p>
                                    </div>
                                </div>
                            </div>

                                </div>
                        </div>

                    </div>
                    <div className="col-xl-6 col-12">

                        <div style={{ border: "1px solid #eee", margin: "12px", background: "#fafafa", borderRadius: "10px" }}>

                            <div style={{ padding: "10px" }}>
                            <div className="row">
                                    <h5>Formulas</h5>

                                    <div className="col-12 col-xl-12" style={{ marginTop: "12px" }} >
                                        <div>
                                            <div className="form-check form-switch col-12" style={{ marginTop: "6px" }} >
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={product.isPreloaded} onChange={(e) => updateisPreloaded(e)} />
                                        <label className="form-check-label" >Is Pre loaded to contract</label>
                                            </div>
                                        </div>
                                    </div>

                                <div className="col-12 col-xl-12" style={{marginTop:"28px"}} >
                                    <div>
                                            <label>Validity Formula</label>
                                            <input placeholder="e.g area > 50" className="form-control" value={product.validityFormula} onChange={(e) => updateValidityFormula(e)}></input>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-12" style={{ marginTop: "14px" }}>
                                    <p><i className="bi bi-info-circle"></i> &nbsp; <b>Validity Formulas</b> are rules that allow a product to be selected.</p>
                                    </div>
                                </div>
                            <div className="row" style={{ marginTop: "14px"  }}>
                                <div className="col-12 col-xl-12">
                                    <div>
                                            <label>Authorisation Formula</label>
                                            <input placeholder="e.g. role='Sales Admin'" className="form-control" value={product.authorisationFormula} onChange={(e) => { updateAuthorisationFormula(e) }}></input>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-12" style={{ marginTop: "20px" }}>
                                    <p><i className="bi bi-info-circle"></i> &nbsp;<b>Authorisation Formulas</b> are rules that restrict this product to certain people.</p>
                                </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                </div>
            </Container>
        </>

    );

}

