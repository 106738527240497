import React, { Component, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { getHumanFriendlyDateString } from 'utils/DateHelper'
import { db } from 'utils/db'
import { LookupHelper } from 'utils/LookupHelper'


export function NotificationRow(props) {

    let item = JSON.parse(props.item.contents);

    function getAlertMethod(item) {
        switch (item.alertMethod) {
            case "EMAIL":
                return (
                    <i className="bi bi-envelope"></i>
                )
            case "SMS":
                return (
                    <i className="bi bi-phone"></i>
                    )
            case "BOTH":
                return (
                    <><i className="bi bi-phone"></i> <i className="bi bi-envelope"></i></>
                )
            default:
                return <></>
        }
    }

    return (
        <div className="row notification-row">
            <div className={"col-2 status-" + item.priority.toLowerCase()}>
                {getAlertMethod(item)} {item.priority} 
            </div>
            <div className="col-10">
                {item.title}
                <br />
                <span className="badge btn-border" style={{ color:"#888", fontWeight:"200"}}>{getHumanFriendlyDateString(item.created)}</span>
            </div>
        </div>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/