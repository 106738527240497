import React, { Component } from 'react';
import packageJson from '../../package.json';
import logo from './../images/orbit-dark.png';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import metadata from 'metadata.json';

export class Splash extends Component {

  render() {

      return (
          <div className="App anim-fade">
              <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  <h2>{packageJson.name}</h2>
                  <p className="Caption-info">react {packageJson.dependencies.react} - scr {packageJson.dependencies['react-scripts']} </p>
                  <p className="Caption-info"><strong>built with &#9825; .net 6 &amp; bootstrap 5</strong> </p>
                  <p className="Caption-info-primary">{`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</p>
              </header>
          </div>
      
    );
  }
}
