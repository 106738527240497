import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Booth from 'images/booth.png'
import Design from 'images/design.png'
import Carousel from 'react-bootstrap/Carousel';
import orbitWorld from 'images/orbit-grey.png'
import { Modal, Button } from 'react-bootstrap'
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import {Bruno } from 'pages/Bruno'
import metadata from 'metadata.json';

function WelcomeBanner() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();




    return (
        <section className="jumbotron text-center anim-fade" style={{ backgroundColor: '#fff'}}>
            <div className="container">
                <div className="row">
                    <div className="offset-md-2 col-md-8">
                        <div className="row not-onphone " >
                            <img src={orbitWorld} alt="orbit world" className="jumbo-logo" />
                        </div>

                        <h1 className="jumbotron-heading">orbit {`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}</h1>
                        <p className="lead text-muted">Online Rebooking IT</p>
                        <div className="btn-jumbotron">
                            <Link  className="btn btn-primary btn-action-sm" to="/login">login</Link>
                            <Link className="btn btn-light btn-action" to="/requestaccess">Request Access</Link>
                        </div>
                        <div className="card mb-4" style={{ marginTop: "30px" } }>
                            <div className="card-body">
                                <p className="card-text"><b>Hello, and welcome to this demo!</b> This is a Progressive Web Application (pwa) - which means it can be installed to your mobile device and will work offline - just like the original Orbit application (except better) </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={handleShow}>View More</button>
                                    </div>
                                    <small className="text-muted">8th Nov 2023: Steve Parker</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
   
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Hey Orbit Fan..</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel variant="dark" >
                        <Carousel.Item interval={null}>
                            <img src={Design} className="d-block w-100" />
                            <p style={{ marginBottom:"60px" }}>
                                This is a prototype of a new version of the Orbit software, developed by Informa (then UBM) <i>almost 10 years ago</i> in 2012. This demo has been created by the original person that came up with the idea and wrote the original software, alongside a team that were outsourced in 2018.
                            </p>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={null}>
                            <img src={Booth} className="d-block w-100" />
                            <p style={{ marginBottom: "60px" }}>
                                Since 2012, the Event and IT industry has moved on <i>significantly</i>. New technologies and approaches are now available to make rebooking software much more efficient and adaptive to demands. This software demonstrates what can be achieved by upgrading ORBIT to 2023 standards.
                            </p>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                     </Button>
                </Modal.Footer>
            </Modal>

        </section>
    );

}

export default WelcomeBanner;