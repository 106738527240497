import React, { Component, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { db } from 'utils/db'
import { LookupHelper } from 'utils/LookupHelper'
import { useNavigate, useLocation } from "react-router-dom";
import { setScrollOffset } from 'utils/UXHelper'

export function ExhibitorRow(props) {


    let navigate = useNavigate();

    let exhibitor = JSON.parse(props.item.contents);

    function showContract() {
        if (props.saveScroll) props.saveScroll();

        navigate("/contractpage", { state: { exhibitor: exhibitor } });
    }

    return (
        <div className="row orbit-row" key={exhibitor.tableGuid} >
            <div className="col-md-12">
                <div className={"badge bg-light  status-badge status-" + LookupHelper.rebookStatusText(exhibitor.rebookStatus).toLowerCase()}><span className="rebook-status-text">{LookupHelper.rebookStatusText(exhibitor.rebookStatus)}</span></div>
                <div className="exhibitorName pointer-col" onClick={() => showContract(exhibitor)}><span >{exhibitor.exhibitorName}</span> <span className={"sponsor-type sponsor-type-icon-" + exhibitor.sponsorType.toLowerCase()} > <i className="bi bi-bookmark-fill"></i></span><span className="booth-number"><span className="booth-label">booth</span> {exhibitor.standNumber}</span>  
                </div>
                <div className="exhibitor-info">
                    <button className="btn btn-light" onClick={() => props.dialogShow(exhibitor)}><i className="bi bi-three-dots"></i></button>
                </div>
            </div>
        </div>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/