import React, { Component, useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'



export function ServerErrors() {

    let navigate = useNavigate();
    const [errors, setErrors] = useState();

    useEffect(() => {

        async function getErrors() {
            const items = await DataHelper.getErrors();
            setErrors(items);
        }

        getErrors();
        
    }, []);


    if (!errors) return <><PageSpinner /></>

    function showTrace(item) {

        window.alert(item.innerMessage + item.trace);
    }

    return (
        <Container >
        <motion.div className="page-header-section"
        >
            <PageHeader pageName={"Server Faults"} showTime={false} />
                {errors?.map(item => (
                    <div className="row orbit-row" key={item.id}>
                        <div className="col-12 col-md-3">
                            <b><i className="bi bi-exclamation-triangle"></i> {new Date(item.dated.toString()).toUTCString()}</b>
                        </div>
                        <div className="col-12 col-md-9 pointer-col" onClick={() => showTrace(item) }>
                            {item.message }
                        </div>
                    </div>

                        )) }

            </motion.div>
        </Container>
    );

}


