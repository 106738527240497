import React, { Component, } from 'react';
import Button from 'react-bootstrap/Button';
import './NavMenu.css';
import logo from 'images/orbit-green.png'
import logooffline from 'images/orbit-dark.png'
import { NetworkStatus } from 'utils/NetworkStatus'
import Sidebar from './../sidebar/Sidebar'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import ec from 'images/ec.png'
import orbitLogo from 'images/orbit-dark.png'
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RemoveLocal, ReadLocal } from 'utils/LocalStorageHelper'
import Dropdown from 'react-bootstrap/Dropdown';
import { setScrollOffset, roleClassName } from 'utils/UXHelper'
import { UserInitials } from 'utils/LocalStorageHelper'



class NavMenu extends Component {
    static displayName = NavMenu.name;



    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSideBarClose = this.handleSideBarClose.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.state = {
            collapsed: true,
            show: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    linkTo(path) {
        setScrollOffset(0);
        this.props.navHook(path);
    }

    logOut() {
        RemoveLocal(global.config.LOGIN_KEY);
        this.props.navHook("/");
    }

    handleSideBarClose(withUrl) {
        this.setState({
            show: false
        })
        if (withUrl) {
            this.props.navHook(withUrl);
        }
    }

    handleShow() {
        this.setState({
            show: true
        })
    }

    NavBrand() {
        let login = ReadLocal(global.config.LOGIN_KEY);
        if (login) {
            return(
            <Navbar.Brand href="#home">
                    <img
                    src={ec}
                    width="30"
                    height="30"
                    className="d-inline-block align-top site-logo"
                />{' '}
                <span className="show-name">Enterprise Connect 2023</span>
                </Navbar.Brand>
                )
        }
        else {
            return (
            <Navbar.Brand href="#home">
                    <img
                    src={orbitLogo}
                    width="30"
                    height="30"
                        className="d-inline-block align-top App-logo-small"
                />{' '}
                orbit-pwa
                </Navbar.Brand>
                )
        }

    }

    NavMenu() {

        let login = ReadLocal(global.config.LOGIN_KEY);

        if (login) {

            const user = JSON.parse(login).user;

            return (
                <Nav className="not-onphone">
                    <Link to="/dashboard" className="nav-link" >Dashboard</Link>
                    <Link to="/reception" className={roleClassName("nav-link", "reception,admin")}>Appointments</Link>
                    <Link to="/exhibitorlisting" className={roleClassName("nav-link", "manager,admin")}>Opportunities</Link>

                    <Dropdown className={roleClassName("", "admin")}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            Admin
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => this.linkTo("/templates")}>Templates</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/productlisting") }>Products</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/notifyalertlisting")}>Alert Rules</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/timelinelisting")}>Timelines</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/userlisting")}>Users</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/bruno")}>Booth Design</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/salesforceconfiguration")}>Salesforce</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.linkTo("/servererrors")}>Errors</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Link className="nav-link orbit-user" onClick={() => this.logOut()} to="/" >{UserInitials(user.fullName)}</Link>

                </Nav>
                )
        }
        else {
            return (
                <Nav>
                    <Link to="/login" className="nav-link">Login</Link>
                </Nav>
                )
        }


    }

    render() {





        return (
            <>
                <header>
                <Navbar bg="light" variant="light" className="nav-orbit">
                        <Container>
                            {this.NavBrand()}
                            {this.NavMenu() }
                    </Container>
                    </Navbar>
                </header>

                <Button variant="secondary" onClick={this.handleShow} className="action-button rounded-circle">
                    <i className="bi bi-list" style={{ fontSize: "1.4rem" }}></i>
                </Button>

                <Offcanvas show={this.state.show} onHide={this.handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Actions</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body  >
                        <Sidebar handleSideBarClose={this.handleSideBarClose} />
                    </Offcanvas.Body>
                </Offcanvas>


            </>


        );
    }
}

function navParams(Component) {
    return props => <Component navHook={useNavigate()} />;
}

export default navParams(NavMenu);

/*
 * 
 * 
 *           <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
   
                          </Collapse>

          <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
              </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/bruno">bruno</NavLink>
                        </NavItem>
            </ul>
*/

/*
 * 
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>

                        <div className="container-fluid">


                            <NavbarBrand tag={Link} to="/">
                                <NetworkStatus>


                                    {({ online }) => (
                                        <img src={online ? logo : logooffline} className="App-logo-small" alt="logo" style={{display:"none"} } />

                                    )}
                                </NetworkStatus>
                                <img src={ec} style={{width:"28px", height: "28px", marginRight: "10px"} }/>

                                <span style={{ fontWeight:"200", fontSize:"1.2em", paddingTop: "4px"} }>Enterprise Connect 2023</span>

                            </NavbarBrand>


                        </div>

                    </Navbar>
                </header>
*/

/*
 *                             <Button variant="light" onClick={this.handleShow}>
                                <i className="bi bi-list"></i>
                            </Button>

nav-link

*/