import React, { Component } from 'react';
import PageHeader from 'components/form/PageHeader'
import { Link } from 'react-router-dom';
import { db } from "utils/db";
import { useLiveQuery } from "dexie-react-hooks";
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'
import { Container } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { LineChart, Line, Label, ResponsiveContainer } from 'recharts';
import FloorplanControl from 'components/form/FloorplanControl'
import { NotificationRow } from '../components/ui/rows/NotificationRow';
import { DashboardUserRow } from '../components/ui/rows/DashboardUserRow';

import { TimelineControl } from 'components/form/TimelineControl'
import { roleClassName } from 'utils/UXHelper'


export function Dashboard() {

    const exhibitors = useLiveQuery(() => db.exhibitors.toArray());
    const contracts = useLiveQuery(() => db.contracts.toArray());
    const notifications = useLiveQuery(() => db.notifications.orderBy('id').reverse().toArray());
    const timelines = useLiveQuery(() => db.timelines.toArray());
    const users = useLiveQuery(() => db.users.where("deleted").equals(0).toArray());


    if (!exhibitors && !contracts && !notifications && !timelines) return (<div><PageSpinner/></div>)


    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    function formatNumber(number) {
        if (number > 1000000000) {
            return (number / 1000000000).toFixed(2).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toFixed(2).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toFixed(2).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    function getReceptionStats(sponsorType) {

        let checkedIn = 0;
        let total = 0;

        if (!exhibitors) return null;

        for (let i = 0; i < exhibitors.length; i++) {
            const item = exhibitors[i];
            const exhibitor = JSON.parse(item.contents);

            if (sponsorType == "" || sponsorType == exhibitor.sponsorType) {
                total = total + 1;
                if (exhibitor.checkInDate) {
                    checkedIn = checkedIn + 1;
                }
            }
        }
        const percentage = (checkedIn / total) * 100;

        return { total: total, checkedIn: checkedIn, percentage: percentage };

    }

    function checkedInPercentage(sponsorType) {
        const stats = getReceptionStats(sponsorType);
        if (!stats) return "...";
        return (<>{Number.parseFloat(stats.percentage).toFixed(0)}%</>)
    }

    function checkedInStats(sponsorType) {
        const stats = getReceptionStats(sponsorType);
        if (!stats) return "calculating";
        return (<p className="text-center">{stats.checkedIn} out of {stats.total}</p>)
    }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });


    function totalRebook() {
        let total = 0;

        if (!contracts) return 0;

        for (let i = 0; i < contracts.length; i++) {
            let item = contracts[i];
            const contract = JSON.parse(item.contents);

            if (contract.status == 'S' && contract.deleted == 0) {
                total = total + contract.value;
            }
        }
        return total;
    }

    function signedContracts() {

        let data = [];
        if (!contracts) return 0;

        let total = 0;

        for (let i = 0; i < contracts.length; i++) {
            let item = contracts[i];
            const contract = JSON.parse(item.contents);
            total = total + contract.value;

            if (contract.status == 'S' && contract.deleted == 0) {
                data.push({ name: contract.title, value: contract.value, pv: contract.value,  amt: total });
            }
        }
        return data;
    }

    function draftContracts() {

        let data = [];
        if (!exhibitors) return 0;

        let total = 0;

        for (let i = 0; i < exhibitors.length; i++) {
            let item = exhibitors[i];
            const exhibitor = JSON.parse(item.contents);

            if (exhibitor.rebookStatus == 'W' || exhibitor.rebookStatus == 'D') {
                total = total + exhibitor.worth;
                data.push({ name: exhibitor.title, value: exhibitor.worth, pv: exhibitor.worth, amt: exhibitor.worth });
            }
        }
        return data;
    }

    function cancelledContracts() {

        let data = [];
        if (!exhibitors) return 0;

        let total = 0;

        for (let i = 0; i < exhibitors.length; i++) {
            let item = exhibitors[i];
            const exhibitor = JSON.parse(item.contents);

            if (exhibitor.rebookStatus == 'C') {
                total = total + exhibitor.worth;
                data.push({ name: exhibitor.title, value: exhibitor.worth, pv: exhibitor.worth, amt: exhibitor.worth });
            }
        }
        return data;
    }



    function totalDraft() {

        let data = [];
        if (!exhibitors) return 0;

        let total = 0;

        for (let i = 0; i < exhibitors.length; i++) {
            let item = exhibitors[i];
            const exhibitor = JSON.parse(item.contents);

            if (exhibitor.rebookStatus != 'S') {
                total = total + exhibitor.worth;
            }
        }
        return formatNumber(total);
    }



    function totalCancelled() {

        let data = [];
        if (!exhibitors) return 0;

        let total = 0;

        for (let i = 0; i < exhibitors.length; i++) {
            let item = exhibitors[i];
            const exhibitor = JSON.parse(item.contents);

            if (exhibitor.rebookStatus == 'C') {
                total = total + exhibitor.worth;
            }
        }
        return formatNumber(total);
    }




    return (
        <Container>
        <motion.div id="pageHolder"
        >

              <div id="pageContent">
                  <main role="main" className="authcontent">
                      
                      <PageHeader pageName={"Dashboard"} showTime={false} />
                        <div className="page-header-section ">
                            <h1>Enterprise Connect 2023</h1>
                            <div className={roleClassName("row", "reception,admin")}>
                                <h5>Appointments</h5>
                              <div className="col-md-3 col-6">
                                  <div className="card dashboard-card all-checkins">
                                      <div className="dashboard-card-title">
                                          All Check-ins
                                      </div>
                                      <div className="dashboard-card-body">
                                          {checkedInPercentage("")}
                                      </div>
                                      <div className="card-footer">
                                          {checkedInStats("")}
                                      </div>
                                  </div>

                              </div>
                              <div className="col-md-3 col-6">
                                  <div className="card dashboard-card titanium-checkins">
                                      <div className="dashboard-card-title">
                                          Titaniums Check-ins
                                      </div>
                                      <div className="dashboard-card-body">
                                          {checkedInPercentage("Titanium")}
                                      </div>
                                      <div className="card-footer">
                                          {checkedInStats("Titanium")}
                                      </div>
                                  </div>

                              </div>

                              <div className="col-md-3 col-6">
                                  <div className="card dashboard-card diamond-checkins">
                                      <div className="dashboard-card-title">
                                          Diamond Check-ins
                                      </div>
                                      <div className="dashboard-card-body">
                                          {checkedInPercentage("Diamond")}
                                      </div>
                                      <div className="card-footer">
                                          {checkedInStats("Diamond")}
                                      </div>
                                  </div>

                              </div>

                              <div className="col-md-3 col-6">
                                  <div className="card dashboard-card reminders-sent">
                                      <div className="dashboard-card-title">
                                          Reminders Sent
                                      </div>
                                      <div className="dashboard-card-body">
                                          0
                                      </div>
                                      <div className="card-footer">
                                          <p className="text-center">0 SMS - 0 Emails</p>
                                      </div>
                                  </div>

                              </div>

                            </div>
                            <div className={roleClassName("row", "manager,admin")}>
                                <h5 style={{ marginTop: "20px" }}>Opportunities</h5>
                                <div className="col-md-4 col-12">
                                    <div className="card dashboard-card">
                                        <div className="dashboard-card-title">
                                            Closed / Won
                                        </div>
                                        <div className="dashboard-graph-body">
                                            <ResponsiveContainer width="99%" height={200}>
                                            <LineChart data={signedContracts()} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                <Line isAnimationActive={false} type="monotone" dataKey="amt" stroke="#00CC00" />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="name" tick={false } >
                                                    <Label value="Total" position="bottom" />
                                                </XAxis>
                                                <YAxis tickFormatter={DataFormater} />
                                                <Tooltip />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="card-footer">
                                            $ {DataFormater(totalRebook())}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    <div className="card dashboard-card">
                                        <div className="dashboard-card-title">
                                            Open / Potentials
                                        </div>
                                        <div className="dashboard-graph-body">
                                            <ResponsiveContainer width="99%" height={200}>
                                                <LineChart data={draftContracts()} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                    <Line isAnimationActive={false} type="monotone" dataKey="amt" stroke="#FFA500" />
                                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                    <XAxis dataKey="name" tick={false} >
                                                        <Label value="Total" position="bottom" />
                                                    </XAxis>
                                                    <YAxis tickFormatter={DataFormater} />
                                                    <Tooltip />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="card-footer">
                                            $ {totalDraft()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="card dashboard-card">
                                        <div className="dashboard-card-title">
                                            Cancelled / Lost
                                        </div>
                                        <div className="dashboard-graph-body">
                                            <ResponsiveContainer width="99%" height={200}>
                                                <BarChart data={cancelledContracts()} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                    <Bar isAnimationActive={false} type="monotone" dataKey="amt" fill="#cc0000" />
                                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                    <XAxis dataKey="name" tick={false} >
                                                        <Label value="Total" position="bottom" />
                                                    </XAxis>
                                                    <YAxis tickFormatter={DataFormater} />
                                                    <Tooltip />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="card-footer">
                                            $ {totalCancelled()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={roleClassName("row", "manager,admin")}>
                                <h5 style={{ marginTop: "20px" }}>Floorplan</h5>

                                <div className="col-12">
                                    <div className="card dashboard-card" style={{height:"600px"} }>
                                        <div className="dashboard-card-title">
                                            Enterprise Connect 2023<br/>
                                            March 27-30, 2023<br />
                                            Gaylord Palms, Orlando, FL<br />
                                        </div>
                                        <FloorplanControl />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <h5 style={{ marginTop: "20px" }}>Activity</h5>

                                <div className="col-md-6 col-12">
                                    <div className="card dashboard-card scrollable-card">
                                        <div className="dashboard-card-title">
                                            Notifications
                                        </div>
                                        <div className="dashboard-card-body-activity">
                                            {
                                                notifications && notifications.map(item => (
                                                    <NotificationRow item={item} key={item.id} />
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="card dashboard-card scrollable-card timeline-card">
                                        <div className="dashboard-card-title">
                                            Timelines
                                        </div>
                                        <div className="dashboard-card-body-activity">

                                            <TimelineControl timelines={timelines} />

                                        </div>
                                        <div className="card-footer">
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className={roleClassName("row", "admin")}>
                                <h5 style={{ marginTop: "20px" }}>Users</h5>

                                <div className="col-12">
                                    <div className="card dashboard-card">
                                            {
                                                users && users.map(item => (
                                                    <DashboardUserRow item={item} key={item.id} />
                                                ))
                                            }
                                    </div>
                                </div>
                            </div>


                            </div>
                  </main>
              </div>
            </motion.div>
        </Container>
    );
}

