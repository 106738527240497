import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import PageSpinner from 'components/ui/spinner/spinner'
import { Form } from 'react-bootstrap';

import { SaveDraftTemplate, GetDraftTemplate } from 'utils/LocalStorageHelper'

export function TimelineEdit() {

    let navigate = useNavigate();

    const editor = useRef(null);

    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    const [Timeline, setTimeline] = useState();
    const [dated, setDated] = useState();
    const [timed, setTimed] = useState();
    const [content, setContent] = useState();

    const config = {
        readonly: false,
        height: 600,
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],
    };


    useEffect(() => {
        if (!location.state) {
            setTimeline({ title: '', timelineDate: new Date(), isDeleted: 0, showId: 1 });
            setDated(new Date().toISOString().split('T')[0]);
            setTimed(new Date().toLocaleTimeString('en-US', { hour12: false }));
            setContent("");
        }
        else {
            const editTimeline = _.cloneDeep(location.state.item);
            setDated(new Date(editTimeline.timelineDate).toISOString().split('T')[0]);
            setTimed(new Date(editTimeline.timelineDate).toLocaleTimeString('en-US', { hour12: false }));
            setTimeline(editTimeline);
            setContent(editTimeline.markUp);
        }


    }, []);

    if (!Timeline || !dated || !timed ) return;

    console.log(Timeline);
    console.log(content);
    


    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const saveTimeline = async () => {

        setIsSaving(true);
        let item = Timeline

        //-- if this is an update - send the Id
        //if (location.state && location.state.item.Timeline) {
        //    item.TimelineId = Timeline.TimelineId;
        //}
        const eventDate = dated + "T" + timed;
        setTimeline({ ...Timeline, markUp: markUp });
        setTimeline({ ...Timeline, timelineDate: new Date(eventDate) });

        Timeline.markUp = markUp;
        Timeline.timelineDate = new Date(eventDate);
        console.log(markUp);


        const result = await DataHelper.upload(item, db.timelines, item.timelineId, "timeline");

        if (result) {
            navigate("/timelinelisting")
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - this looks like an app fault :(");
        }

    }

    function CancelButton() {
        navigate("/timelinelisting")
    }

    let markUp = Timeline.markUp;

    const updateTitle = (e) => { setTimeline({ ...Timeline, title: e.target.value }); };
    const updateDated = (e) => { setDated(e.target.value); };
    const updateTimed = (e) => { setTimed(e.target.value); };
    const updateRequired = (e) => { setTimeline({ ...Timeline, required: e.target.value }); };
    const updateOptional = (e) => { setTimeline({ ...Timeline, optional: e.target.value }); };
    const updateLocation = (e) => { setTimeline({ ...Timeline, location: e.target.value }); };
    const updateDuration = (e) => { setTimeline({ ...Timeline, duration: parseInt(e.target.value) }); };
    const updateContent = (value) => { markUp = value; };



    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <Container>
                <div className="page-header-section ">
                    <PageHeader pageName={"Timeline"} showTime={false} />

                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-2" onClick={() => CancelButton()}>
                            <button className="btn btn-light" alt="cancel editing"><i className="bi bi-arrow-left-circle"></i> cancel</button>
                        </div>
                        <div className="col-2">
                            <button disabled={isSaving} tooltip="save" className="btn btn-primary" onClick={() => saveTimeline()} ><i className="bi bi-check-lg"></i> save</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-12" >

                            <div style={{ border: "1px solid #eee", marginTop: "12px", background: "#fafafa", borderRadius: "10px" }}>
                                <div style={{ padding: "10px" }}>
                                    <h5>Timeline Entry</h5>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <input placeholder="e.g. Pre-sales Meet up (booth 1100)" value={Timeline.title} onChange={(e) => updateTitle(e)} className="form-control"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-6 col-md-2 entry-margin">
                                            <label>Date</label>
                                            <Form.Control type="date" name="time" placeholder="Time of event" value={dated} onChange={(e) => updateDated(e)} />
                                        </div>
                                        <div className="col-6 col-md-2 entry-margin">
                                            <label>Time</label>
                                            <Form.Control type="time" name="time" placeholder="Time of event" value={timed} onChange={(e) => updateTimed(e)} />
                                        </div>
                                        <div className="col-12 col-md-2 entry-margin">
                                            <label>Duration</label>
                                            <select className="form-select" id="entityId" value={Timeline.duration} onChange={(e) => updateDuration(e)}>
                                                <option key={1} value="5">5 mins</option>
                                                <option key={2} value="10">10 mins</option>
                                                <option key={3} value="15">15 mins</option>
                                                <option key={4} value="20">20 mins</option>
                                                <option key={5} value="30">30 mins</option>
                                                <option key={6} value="45">45 mins</option>
                                                <option key={7} value="60">1 hour</option>
                                                <option key={8} value="90">1.5 hours</option>
                                                <option key={9} value="120">2 hours</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <label>Required</label>
                                            <input placeholder="required people e.g. everyone" value={Timeline.required} onChange={(e) => updateRequired(e)} className="form-control"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <label>Optional</label>
                                            <input placeholder="optional people e.g. steve.parker" value={Timeline.optional} onChange={(e) => updateOptional(e)} className="form-control"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <label>Location</label>
                                            <input placeholder="paste a link from maps" value={Timeline.location} onChange={(e) => updateLocation(e)} className="form-control"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <label>details</label>
                                            <JoditEditor
                                                ref={editor}
                                                value={content}
                                                config={config}
                                                onChange={updateContent}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>

    );

}

