import AppRoutes from '../AppRoutes';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion'

function AnimatedRoutes() {

    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
        {
            AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })
            }
            </Routes>
        </AnimatePresence>
    )

}

export default AnimatedRoutes;