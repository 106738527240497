import Dexie from 'dexie'
import _ from 'lodash';

class OrbitDB extends Dexie {

    constructor() {
        super('OrbitDB');
        this.version(14).stores({
            shows: "++id, tableGuid,title,deleted,contents,clientGuid",
            exhibitors: "++id, tableGuid, title, deleted, contents,clientGuid",
            contacts: "++id, tableGuid, title, deleted, contents,clientGuid",
            contracts: "++id, tableGuid, title, deleted, contents,clientGuid",
            products: "++id, tableGuid, title, deleted, contents,clientGuid",
            users: "++id, tableGuid, title, deleted, contents,clientGuid",
            templates: "++id, tableGuid, title, deleted, contents,clientGuid",
            notifications: "++id, tableGuid, title, deleted, contents,clientGuid",
            notifyalerts: "++id, tableGuid, title, deleted, contents,clientGuid",
            timelines: "++id, tableGuid, title, deleted, contents,clientGuid",
            contractDetails: "++id, tableGuid, title, deleted, contents,clientGuid",
            uploads: "++id, tableGuid, contents, entryType, uploaded, requestCreated, userId",
            messages: "++id, tableGuid, title, deleted, contents,clientGuid",
        });
        this.updateItem = this.updateItem.bind(this);
        this.addItem = this.addItem.bind(this);

    }

    //----------------- DataSaving stuff

    async addItem(item, dataset) {
        dataset.add({ tableGuid: item.tableGuid, contents: JSON.stringify(item), deleted: item.deleted, title: item.title, clientGuid: item.clientGuid })
    }

    async updateItem(item, dataset, id) {
        dataset.update(id, { contents: JSON.stringify(item), deleted: item.deleted, title: item.title, tableGuid: item.tableGuid })
    }

    async upsertItem(item, dataset) {

        if (!item) return;

        let data = null;

        //-- look to see if we already have this
        if (item.tableGuid) {
            data = await dataset.get({ tableGuid: item.tableGuid });
        }

        if (!data && item.clientGuid) data = await dataset.get({ clientGuid: item.clientGuid });

        if (!data) await db.addItem(item, dataset);
        if (data) {
            await db.updateItem(item, dataset, data.id);
        }
    }

    async addItems(items, dataset) {
        dataset.clear();

        if (!items) return;

        for (let i = 0; i < items.length; i++) {
            await this.addItem(items[i], dataset);
        }
    }



    //---------------- SYNCING/UPLOADING TABLES (offline and failed uploads)

    async uploadsWaiting() {
        const waitingList = await db.uploads.where({ uploaded: 0 }).toArray();
        return waitingList;
    }

    async uploadSuccesful(uploadId) {
        const data = await db.uploads.get({ id: uploadId });
        if (data) {
            db.uploads.update(data.id, { uploaded: 1 });
        }

    }

    async queueUpload(entry, entryType, userId) {
        //-- look to see if we are waiting to upload this already.
        let data = null;

        if (entry.tableGuid) {
            data = await db.uploads.get({ tableGuid: entry.tableGuid, entryType: entryType, uploaded: 0 });
        }

        if (data) {
            db.uploads.update(data.id, {
                contents: JSON.stringify(entry), tableGuid: entry.tableGuid,
                uploaded: 0, entryType: entryType, requestCreated: new Date(), userId: userId
            } );
        }
        else {

            let tableGuid = entry.tableGuid ? entry.tableGuid : null;

            db.uploads.add({
                contents: JSON.stringify(entry), tableGuid: tableGuid,
                uploaded: 0, entryType: entryType, requestCreated: new Date(), userId: userId
            } );
        }
    }





}

export const db = new OrbitDB();

