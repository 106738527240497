import { Canvas } from '@react-three/fiber'
import { OrbitControls, ContactShadows } from "@react-three/drei"

import { Suspense } from 'react'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Controls from 'componentsthree/Controls'
import Level from 'componentsthree/Level'
import Sudo from 'componentsthree/Sudo'
import Camera from 'componentsthree/Camera'
import Cactus from 'componentsthree/Cactus'
import Cube from 'componentsthree/Cube'
import Pyramid from 'componentsthree/Pyramid'
import React, { Component } from 'react';
import FBXLoader from 'componentsthree/FBXLoader'

export class Bruno extends Component {
    render() {
        return (
            <div className="bruno-canvas">
                <Canvas flat linear dpr={[1, 1]} camera={{ fov: 60, position: [0, 380, 90] }} >


                    <Suspense fallback={<FBXLoader />}>
                    <color attach="background" args={['#fff']} />
                    <ambientLight />
                        <group position-y={-100.5} position-x={-20} dispose={null}>
                                <Level />
                                <ContactShadows rotation-x={Math.PI / 2} position={[0, -0.8, 0]} opacity={0.25} width={10} height={10} blur={2} far={1} />
                        </group>

                    
                    </Suspense>
                    <OrbitControls autoRotate={true} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={false} />
                    </Canvas>
            </div>
        )
    }
}
/*
 * 
 *                 <EffectComposer multisampling={8}>
                    <Bloom intensity={0.4} luminanceThreshold={0.8} luminanceSmoothing={0.01} kernelSize={5} />
                    </EffectComposer>
*/
