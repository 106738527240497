import { db } from './db';
import React, { Component, useState } from 'react';

import { LoggedIn, ReadLocal, WriteLocal, GetLastSyncDate, CreateGUID } from 'utils/LocalStorageHelper'


class LookupHelp {

    rebookStatusText(status) {
        let text = '';
        switch (status.toUpperCase()) {
            case 'D':
                text = 'DRAFT';
                break;
            case 'W':
                text = 'WAITING';
                break;
            case 'C':
                text = 'CANCELLED';
                break;
            case 'S':
                text = 'SIGNED';
                break;

        }
        return text;
    }

}

export const LookupHelper = new LookupHelp();