import * as React from 'react';
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { useNavigate } from "react-router-dom";
import { db } from 'utils/db'

export function NotifyAlertRow(props) {

    let navigate = useNavigate();

    let NotifyAlert = JSON.parse(props.item.contents);

    function NotifyAlertEdit () {
        navigate("/NotifyAlertedit", { state: { item: NotifyAlert } });
    }

    async function deleteNotifyAlert() {
        NotifyAlert.deleted = 1;
        await DataHelper.upload(NotifyAlert, db.notifyalerts, NotifyAlert.notifyAlertId, "notifyalert");
    }


    function getAlertMethod(item) {
        switch (item.alertMethod) {
            case "EMAIL":
                return (
                    <i className="bi bi-envelope"></i>
                )
            case "SMS":
                return (
                    <i className="bi bi-phone"></i>
                )
            case "BOTH":
                return (
                    <><i className="bi bi-phone"></i> <i className="bi bi-envelope"></i></>
                )
            default:
                return <></>
        }
    }

    return (
        <div className="row notifyalert-row" key={NotifyAlert.tableGuid}>
            <div className="col-11" >
                <div className="notifyalert-type badge bg-light not-onphone">{NotifyAlert.priority} {getAlertMethod(NotifyAlert)} </div>
                <div className="notifyalert-title" onClick={() => NotifyAlertEdit()} >{NotifyAlert.title} {NotifyAlert.isDisabled == 1 ? <i className="bi bi-lock-fill"></i> : <></>}
                    <br /> <code> {NotifyAlert.rule}</code>
                </div>
            </div>
            <div className="col-1">
                <button onClick={() => deleteNotifyAlert()} className="NotifyAlert-delete btn btn-delete btn-light"><i className="bi bi-trash"></i></button>
            </div>
        </div>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/