import React from "react";
import PropTypes from "prop-types";
import { Button } from "bootstrap";
import _ from 'lodash';
import SignatureCanvas from 'react-signature-canvas'


export default class SignatureModal extends React.Component {


    clear = () => {
        this.sigPad.clear()
    }

    constructor(props) {
        super(props);
        this.state = { trimmedDataURL: null }
        this.sigPad = {}
    }

    applyChanges = e => {

        let trimmedData = null;

        if (!this.sigPad.isEmpty()) {
            trimmedData = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
        }

        this.props.setSignature && this.props.setSignature(trimmedData)
        this.props.onClose && this.props.onClose(e);
    }

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };


    render() {

        if (!this.props.show) {
            return null;
        }


        return (
            <>
            <div className="popup-background-dark" onClick={this.onClose}>
            </div>
                <div className="signatureModal">

                    <SignatureCanvas penColor='black'
                        canvasProps={{ width: 360, height: 200, className: 'sigCanvas' }}
                    ref={(ref) => { this.sigPad = ref }}                    />

                    <div className="actions">
                        <button className="btn btn-primary" onClick={this.applyChanges} alt="apply">Save <i className="bi bi-save"></i></button>
                        <button className="btn btn-dark" onClick={this.onClose} alt="cancel editing">Close <i className="bi bi-x-circle"></i></button>
                        <button className="btn btn-light" onClick={this.clear}>Clear</button>
                </div>
                </div>
            </>
        );
    }

}

SignatureModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

