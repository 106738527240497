import { Counter } from "./pages/Counter";
import { FetchData } from "./pages/FetchData";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Splash } from "./pages/Splash";
import { Bruno } from "./pages/Bruno";
import { Dashboard } from "./pages/Dashboard";
import { Reception } from "./pages/Reception";
import { Templates } from "./pages/Templates";
import { TemplateEdit } from "./pages/TemplateEdit";
import { ProductListing } from "./pages/ProductListing";
import { ProductEdit } from "./pages/ProductEdit";
import { ServerErrors } from "./pages/ServerErrors";
import RequestAccess from "./pages/RequestAccess";
import { ExhibitorListing } from "./pages/ExhibitorListing";
import { ContractPage } from "./pages/ContractPage";
import { NotifyAlertListing } from "./pages/NoitfyAlertListing";
import { NotifyAlertEdit } from "./pages/NotifyAlertEdit";
import { TimelineListing } from "./pages/TimelineListing";
import { TimelineEdit } from "./pages/TimelineEdit";
import { UserListing } from "./pages/UserListing";
import { UserEdit } from "./pages/UserEdit";
import { SalesforceConfiguration } from "./pages/SalesforceConfiguration"; 


const AppRoutes = [
    {
        path: '/useredit',
        element: <UserEdit />
    },
    {
        path: '/userlisting',
        element: <UserListing />
    },
    {
        path: '/timelinelisting',
        element: <TimelineListing />
    },
    {
        path: '/timelineedit',
        element: <TimelineEdit />
    },
    {
        path: '/notifyalertedit',
        element: <NotifyAlertEdit />
    },
    {
        path: '/notifyalertlisting',
        element: <NotifyAlertListing />
    },
    {
        path: '/contractpage',
        element: <ContractPage />
    },
    {
        path: '/exhibitorlisting',
        element: <ExhibitorListing />
    },
    {
        path: '/salesforceconfiguration',
        element: <SalesforceConfiguration />
    },
    {
        path: '/requestaccess',
        element: <RequestAccess />
    },

    {
        path: '/productedit',
        element: <ProductEdit />
    },

    {
        path: '/servererrors',
        element: <ServerErrors />
    },
    {
        path: '/productlisting',
        element: <ProductListing />
    },
    {
        path: '/templateedit',
        element: <TemplateEdit />
    },
    {
        path: '/reception',
        element: <Reception />
    },
    {
        path: '/Templates',
        element: <Templates />
    },
    {
        path: '/index.html',
        element: <Home />
    },
    {
        index: true,
        element: <Home />
    },
    {
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        path: '/bruno',
        element: <Bruno />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/splash',
        element: <Splash />
    }

];

export default AppRoutes;
