import React from "react";
import PropTypes from "prop-types";
import { Button } from "bootstrap";
import _ from 'lodash';


export default class ProductsModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = { products: [], refreshed: false };
    }

    componentDidMount() {
        if (!this.props.exhibitor) {
            return
        }
    }

    formatValue(value) {

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(value);

    }

    removeItem(product) {
        let allProducts = _.cloneDeep(this.state.products)
        for (let i = 0; i < allProducts.length; i++) {
            let item = allProducts[i];
            if (item.productId == product.productId) {
                allProducts[i].deleted = 1;
            }
        }
        this.setState({ products: allProducts });
    }

    applyChanges = e => {
        this.setState({ refreshed: false });
        this.props.onUpdateProducts && this.props.onUpdateProducts(this.state.products);
        this.props.onClose && this.props.onClose(e);
    }

    onClose = e => {
        this.setState({ products: [], refreshed: false});
        this.props.onClose && this.props.onClose(e);
    };


    render() {

        if (!this.props.show || !this.props.products) {
            return null;

        }

        if (this.state.refreshed == false) {
            this.setState({ refreshed: true, products: this.props.products });
        }

        const products = this.state.products.filter((e) => { return e.deleted == 0 });

        return (
            <>
            <div className="popup-background-dark" onClick={this.onClose}>
            </div>
            <div className="exhibitorModal">
                    <h2>Products</h2>
                    <div className="content" style={{ padding: "30px" }} >
                        {products.map(item => (
                            <div className="row" style={{ marginBottom:"8px"} }>
                                <div className="col-12 col-md-8">{item.isLocked ?
                                    <><button disabled className="btn btn-light"><i className="bi bi-lock-fill"></i></button>{item.title}</>
                                    :
                                    <><button className="btn btn-danger" onClick={() => this.removeItem(item) } ><i className="bi bi-trash"></i></button> {item.title}</>
                                } </div>
                                <div className="col-6 col-md-4 not-onphone">{this.formatValue(item.value)}</div>
                            </div>
                        ))}
                        <hr/>
                </div>
                    <div className="actions">
                        <button className="btn btn-primary" onClick={this.applyChanges} alt="apply">Save <i className="bi bi-save"></i></button>
                        <button className="btn btn-dark" onClick={this.onClose} alt="cancel editing">Close <i className="bi bi-x-circle"></i></button>
                </div>
                </div>
            </>
        );
    }

}

ProductsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

