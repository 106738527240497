import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import { UserInitials } from 'utils/LocalStorageHelper'
import PageSpinner from 'components/ui/spinner/spinner'
import { Form } from 'react-bootstrap';


export function UserEdit() {

    let navigate = useNavigate();

    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        if (!location.state) {
            setUser({ userId: null, role: 'Admin', workingShowId: 1, isDeleted: 0 });
        }
        else {
            const edituser = _.cloneDeep(location.state.item);
            setUser(edituser);
        }
    }, []);

    if (!user) return (<PageSpinner />);

    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const saveuser = async () => {

        setIsSaving(true);
        let item = user
        item.title = user.fullName

        const result = await DataHelper.upload(item, db.users, item.userId, "user");

        if (result) {
            navigate("/userlisting")
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - this looks like an app fault :(");
        }

    }

    function CancelButton() {
        navigate("/userlisting")
    }


    const updateFullName = (e) => { setUser({ ...user, fullName: e.target.value }); };
    const updateUserName = (e) => { setUser({ ...user, userName: e.target.value }); };
    const updatePassword = (e) => { setUser({ ...user, userPassword: e.target.value }); };
    const updateEmail = (e) => { setUser({ ...user, email: e.target.value }); };
    const updatePhone = (e) => { setUser({ ...user, phone: e.target.value }); };
    const updateStatusOn = (e) => { setUser({ ...user, status: "active" }); };
    const updateStatusOff = (e) => { setUser({ ...user, status: "inactive" }); };
    const updateCountryCode = (e) => { setUser({ ...user, countryCode: e.target.value }); };
    const updateRole = (e) => { setUser({ ...user, role: e.target.value }); };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <Container>
                <div className="page-header-section ">
                    <PageHeader pageName={"user"} showTime={false} />

                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-2" onClick={() => CancelButton()}>
                            <button className="btn btn-light" alt="cancel editing"><i className="bi bi-arrow-left-circle"></i> cancel</button>
                        </div>
                        <div className="col-2">
                            <button disabled={isSaving} tooltip="save" className="btn btn-primary" onClick={() => saveuser()} ><i className="bi bi-check-lg"></i> save</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-12" >

                            <div style={{ border: "1px solid #eee", marginTop: "12px", background: "#fafafa", borderRadius: "10px" }}>
                                <div style={{ padding: "10px" }}>
                                    <div className="user-circle-name">
                                        {UserInitials(user.fullName)}
                                    </div>
                                    <div className="form-check form-switch active-user-switch">
                                        {user.status == 'active' ?
                                            <>
                                                <label className="form-check-label" for="flexSwitchCheckDefault">Account active</label>
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={(e) => updateStatusOff(e)} checked />
                                            </>
                                            : 
                                            <>
                                                <label className="form-check-label" for="flexSwitchCheckDefault" style={{ opacity: "0.3" }}>Account inactive</label>
                                                <input className="form-check-input" type="checkbox"   id="flexSwitchCheckDefault" onClick={(e) => updateStatusOn(e)} />
                                            </>
                                            }
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-12">
                                            <label>Full Name</label>
                                            <input placeholder="Full Name" value={user.fullName} onChange={(e) => updateFullName(e)} className="form-control"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-md-6 col-12">
                                            <label>User name</label>
                                            <input placeholder="user Name" value={user.userName} onChange={(e) => updateUserName(e)} className="form-control"></input>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label>Password</label>
                                            <input placeholder="password" value={user.userPassword} onChange={(e) => updatePassword(e)} className="form-control" type="password"></input>
                                        </div>
                                    </div>

                                    <div className="row data-row">
                                        <div className="col-md-6 col-12">
                                            <label>Email</label>
                                            <input placeholder="user Name" value={user.email} onChange={(e) => updateEmail(e)} className="form-control"></input>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label>Phone</label>
                                            <input placeholder="phone" value={user.phone} onChange={(e) => updatePhone(e)} className="form-control"></input>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div style={{ border: "1px solid #eee", marginTop: "12px", background: "#fafafa", borderRadius: "10px" }}>
                                <div style={{ padding: "10px" }}>

                                    <div className="row data-row">
                                        <div className="col-12 col-md-4 entry-margin">
                                            <label>ISO Country Code</label>
                                            <input placeholder="Country Code" maxLength="5" value={user.countryCode} onChange={(e) => updateCountryCode(e)} className="form-control"></input>
                                        </div>
                                        <div className="col-12 col-md-3 entry-margin">
                                            <label>Role</label>
                                            <select className="form-select" id="entityId" value={user.role} onChange={(e) => updateRole(e)}>
                                                <option key={1} value="Admin">Admin</option>
                                                <option key={2} value="Manager">Manager</option>
                                                <option key={3} value="Reception">Reception</option>
                                                <option key={3} value="Unassigned">Unassigned</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Container>
        </>

    );

}

