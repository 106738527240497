import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import PageSpinner from 'components/ui/spinner/spinner'


import { SaveDraftTemplate, GetDraftTemplate } from 'utils/LocalStorageHelper'

export function NotifyAlertEdit() {

    let navigate = useNavigate();

    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    const [NotifyAlert, setNotifyAlert] = useState({});

    const templates = useLiveQuery(() => db.templates.where("deleted").equals(0).toArray());


    useEffect(() => {
        if (!location.state) {
            setNotifyAlert({ title: '', isDisabled: false, priority: "LOW", entity: "exhibitor", isDeleted: 0, showId: 1 });
        }
        else {
            const editNotifyAlert = _.cloneDeep(location.state.item);
            setNotifyAlert(editNotifyAlert);
        }

    }, []);

    if (NotifyAlert == null) return;

    console.log(NotifyAlert);

    if (!templates) return <><PageSpinner /></>;


    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const saveNotifyAlert = async () => {

        setIsSaving(true);
        let item = NotifyAlert

        //-- if this is an update - send the Id
        //if (location.state && location.state.item.NotifyAlert) {
        //    item.NotifyAlertId = NotifyAlert.NotifyAlertId;
        //}

        const result = await DataHelper.upload(item, db.notifyalerts, item.NotifyAlertId, "notifyalert");

        if (result) {
            navigate("/NotifyAlertlisting")
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - this looks like an app fault :(");
        }

    }

    function CancelButton() {
        navigate("/NotifyAlertlisting")
    }


    const updateTitle = (e) => { setNotifyAlert({ ...NotifyAlert, title: e.target.value }); };
    const updateEntity = (e) => { setNotifyAlert({ ...NotifyAlert, entity: e.target.value }); };
    const updateRule = (e) => { setNotifyAlert({ ...NotifyAlert, rule: e.target.value }); };
    const updateRecipients = (e) => { setNotifyAlert({ ...NotifyAlert, recipients: e.target.value }); };
    const updateAlertMethod = (e) => { setNotifyAlert({ ...NotifyAlert, alertMethod: e.target.value }); };
    const updateEmailTemplateId = (e) => { setNotifyAlert({ ...NotifyAlert, emailTemplateId: e.target.value }); };
    const updateSmsTemplateId = (e) => { setNotifyAlert({ ...NotifyAlert, smstemplateId: e.target.value }); };


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <Container>
                <div className="page-header-section ">
                    <PageHeader pageName={"Contract Templates"} showTime={false} />

                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-2" onClick={() => CancelButton()}>
                            <button className="btn btn-light" alt="cancel editing"><i className="bi bi-arrow-left-circle"></i> cancel</button>
                        </div>
                        <div className="col-2">
                            <button disabled={isSaving} tooltip="save" className="btn btn-primary" onClick={() => saveNotifyAlert()} ><i className="bi bi-check-lg"></i> save</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-12" >

                            <div style={{ border: "1px solid #eee", marginTop: "12px", background: "#fafafa", borderRadius: "10px" }}>
                                <div style={{ padding: "10px" }}>
                                    <h5>Notify Alert Details</h5>

                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="col-9 col-md-8 col-xl-10">
                                            <input placeholder="title e.g. exhibitor.ExhibitorName rebooked " value={NotifyAlert.title} onChange={(e) => updateTitle(e)} className="form-control"></input>
                                        </div>
                                        <div className="col-3 col-md-2 col-xl-2">
                                            <label className="visually-hidden" >entity</label>
                                            <select className="form-select" id="entityId" value={NotifyAlert.entity} onChange={(e) => updateEntity(e)}>
                                                <option key={1} value="exhibitor">exhibitor</option>
                                                <option key={2} value="show">show</option>
                                                <option key={3} value="contract">contract</option>
                                                <option key={4} value="login">login</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="col-12">
                                            <textarea
                                                className="rule-input form-control"
                                                value={NotifyAlert.rule}
                                                onChange={(e)=>updateRule(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="col-12">
                                            <label>Recipients</label>
                                            <input
                                                className="form-control"
                                                value={NotifyAlert.recipients}
                                                onChange={(e) => updateRecipients(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className="col-12 col-md-4 entry-margin">
                                            <label>Alert Type</label>
                                            <select className="form-select" id="entityId" value={NotifyAlert.alertMethod} onChange={(e) => updateAlertMethod(e)}>
                                                <option key={1} value="EMAIL">EMAIL</option>
                                                <option key={2} value="SMS">SMS</option>
                                                <option key={3} value="BOTH">BOTH</option>
                                            </select>
                                        </div>


                                        <div className="col-12 col-md-4 entry-margin">
                                            <label>Email template</label>
                                            <select name="emailTemplateField" className="form-select" value={NotifyAlert.emailTemplateId} onChange={(e) => updateEmailTemplateId(e)} >
                                                {templates.map(item =>
                                                    <option key={item.key} value={item.tableGuid}>{item.title}</option>
                                                )};
                                            </select>
                                        </div>

                                        <div className="col-12 col-md-4 entry-margin">
                                            <label>SMS template</label>
                                            <select name="smsTemplateField" className="form-select" value={NotifyAlert.smstemplateId} onChange={(e) => updateSmsTemplateId(e)} >
                                                {templates.map(item =>
                                                    <option key={item.key} value={item.tableGuid}>{item.title}</option>
                                                )};
                                            </select>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>

    );

}

