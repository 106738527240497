import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { UserRow } from 'components/ui/rows/UserRow'
import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'
import { useNavigate } from "react-router-dom";
import sflogo from 'images/sf.png'


export function SalesforceConfiguration() {

    
    return (
        <Container>
        <motion.div className="page-header-section" >

                <PageHeader pageName={"Salesforce Configuration"} showTime={false} />

                <div className="row">
                    <img src={sflogo} alt="Salesforce configuration" className="sflogo"/>
                </div>

                <div className="row data-row">
                    <div className="col-12">
                        <label>Instance name</label>
                        <input placeholder="sales force instance name" value="https://instance.salesforce.com/services/data/" className="form-control"></input>
                    </div>
                </div>
                <div className="row data-row">
                    <div className="col-4">
                        <label>Bearer token</label>
                        <input placeholder="bearer token" value="123456789A" type="password" className="form-control"></input>
                    </div>
                    <div className="col-4">
                        <label>Synchronization Frequency</label>
                        <select className="form-select" id="entityId" value="1">
                            <option key={1} value="5">5 mins</option>
                            <option key={2} value="10">10 mins</option>
                            <option key={3} value="15">15 mins</option>
                            <option key={4} value="20">20 mins</option>
                            <option key={5} value="30">30 mins</option>
                            <option key={6} value="45">45 mins</option>
                            <option key={7} value="60">1 hour</option>
                            <option key={8} value="90">1.5 hours</option>
                            <option key={9} value="120">2 hours</option>
                        </select>
                    </div>
                </div>
                <div className="row data-row">
                    <div className="col-4">
                        <label>Show code</label>
                        <input placeholder="show code" value="EC2023-SF" className="form-control"></input>
                    </div>
                </div>

                <div className="row data-row">
                    <div className="col-4">
                        <button disabled="true" tooltip="test connection" className="btn btn-white" ><i class="bi bi-hdd-network-fill"></i> Test Connection</button>
                    </div>
                </div>


                <div className="row">
                </div>


            </motion.div>
        </Container>
    );

}



