import Spinner from 'react-bootstrap/Spinner';

function PageSpinner(props) {
    return (
        <div className={props.isDark ? "popup-background-dark" : "popup-background"}>
            <div className="page-center">
                <Spinner animation="grow" /><br /><span style={{ fontSize: "10px", letterSpacing: "2px" }}>wait..</span>
            </div>
        </div>
    );
}

export default PageSpinner;