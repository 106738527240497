import React from "react";
import PropTypes from "prop-types";
import { db } from 'utils/db'
import { useNavigate } from 'react-router-dom';


class ExhibitorModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = { contacts: [], refreshed: false };
    }

    componentDidMount() {
        if (!this.props.exhibitor) {
            return
        }
    }

    async filterContacts() {
        let filteredContacts = [];
        const data = await db.contacts.toArray();
        for (let i = 0; i < data.length; i++)
        {
            const entry = JSON.parse(data[i].contents);
            if (entry.exhibitorId == this.props.exhibitor.exhibitorId) {
                filteredContacts.push(entry);
            }
        }
        this.setState({ contacts: filteredContacts, refreshed: true });
    }


    linkTo(path) {
        this.props.navHook(path, { state: { exhibitor: this.props.exhibitor } });
    }

    onClose = e => {
        this.setState({ contacts: [], refreshed: false});
        this.props.onClose && this.props.onClose(e);
    };


    onCancelContract = e => {
        this.setState({ contacts: [], refreshed: false });
        this.props.onClose && this.props.onClose(e);
        const exhibitor = this.props.exhibitor;
        this.props.cancelContract && this.props.cancelContract(exhibitor.tableGuid)

    }


    render() {

        if (!this.props.show || !this.props.exhibitor) {
            return null;
        }

        if (!this.state.refreshed) {
            this.filterContacts();
            return null;
        }

        const exhibitor = this.props.exhibitor;
        const contacts = this.state.contacts;

        return (
            <>
            <div className="popup-background-dark" onClick={this.onClose}>
            </div>
            <div className="exhibitorModal">
                    <h2>{exhibitor.exhibitorName}   <span className="sponsor-type-dialog">{exhibitor.sponsorType}</span>  <span className="sponsor-type-dialog-text">{exhibitor.rebookText}</span></h2>
                    <div className="content" style={{ padding: "30px" }} >
                        <h6><i className="bi bi-calendar-event"></i> Appointment</h6>
                        <div className="row">
                            <div className="col-1"><b>{exhibitor.rebookNumber}</b></div>
                            <div className="col-11">Today at 10:30</div>
                        </div>
                        <hr/>
                        <h6><i className="bi bi-person-lines-fill"></i> Contacts</h6>
                        {contacts.map(item => (
                            <div className="row">
                                <div className="col-12 col-md-4">{item.title}</div>
                                <div className="col-12 col-md-4"><a href="tel:{item.phone}">{item.phone}</a></div>
                                <div className="col-12 col-md-4">{item.email}</div>
                            </div>
                        ))}
                        <hr/>
                        <div className="row">
                            <button className="btn btn-primary col-12 col-md-6 btn-dialog" onClick={() => this.linkTo("/contractpage")}>View Contract</button>
                            <button className="btn btn-light btn-border col-12 col-md-6 btn-dialog" onClick={() => this.onCancelContract()}>Cancel</button>
                        </div>
                </div>
                    <div className="actions">
                        <button className="btn btn-dark" onClick={this.onClose} alt="cancel editing">Close <i className="bi bi-x-circle"></i></button>
                </div>
                </div>
            </>
        );
    }

}

ExhibitorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

function navParams(Component) {
    return props => <Component navHook={useNavigate()} cancelContract={props.cancelContract} show={props.show} exhibitor={props.exhibitor} onClose={props.onClose} />;
}

export default navParams(ExhibitorModal);

/*
 



*/