import { Html, useProgress } from '@react-three/drei'
import React, { Suspense, useRef, useState, useEffect } from "react"

export default function FBXLoader() {
    const { active, progress, errors, item, loaded, total } = useProgress()
    useEffect(() => {
        console.log(active, progress)
    }, [active, progress])
    return <Html center>loading model</Html>
//    return <Html center>{progress} % loaded</Html>
}