import React, { Component, useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { TimelineRow } from 'components/ui/rows/TimelineRow'
import { DataHelper } from 'utils/DataHelper';
import { Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'
import { Container } from 'react-bootstrap'

export function TimelineListing() {

    let navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');
    const [filterInput, setFilterInput] = useState('');

    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setFilteredResults(Timelines);
    }, []);

    const Timelines = useLiveQuery(() => db.timelines.where("deleted").equals(0).toArray());


    if (!Timelines) return null;

    function addNewTimeline (){
         navigate("/Timelineedit");
    }


    const filterTimelines = (selected) => {
        setFilterInput(selected.target.value);
        const filter = selected.target.value;
        searchItems(searchInput, filter);
    }


    const searchItems = (searchValue, filter = "-") => {
        setSearchInput(searchValue)
        const filteredData = [];

        if (filter == "-") filter = filterInput;
        if (filter == "*") filter = "";


        for (let i = 0; i < Timelines.length; i++) {
            const item = JSON.parse(Timelines[i].contents);
            const Timeline = Timelines[i];

            if (searchValue == "" || item.title.toLowerCase().includes(searchValue.toLowerCase()))
            {
                if (Timeline.deleted == 0) {
                    filteredData.push(Timeline);
                }
            }
        }
        setFilteredResults(filteredData)
 
    }

    return (
        <Container>
            <motion.div className="page-header-section"
        >
           <PageHeader pageName={"Timelines"} showTime={false} />
                <div className="TimelineSearch">
                <div className="row">
                    <div className="input-group-filter col-md-6">
                        <input type="text" className="form-control" placeholder="Search for..." aria-label="Filter" aria-describedby="basic-addon1" onChange={(e) => searchItems(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                        <div className="btn btn-primary" onClick={() => addNewTimeline() }><i className="bi bi-plus-lg"></i>Add</div>
                    </div>
                </div>
            </div>

                <div className="row">
                </div>



            {searchInput.length > 0 || filterInput.length > 0 ?  (

                    filteredResults.map(item => (
                        <TimelineRow item={item} key={item.id} />
                    ))

                ) : (
                    Timelines.map(item => (
                        <TimelineRow item={item} key={item.id} />
                    ))
                )
                }
            
            </motion.div>
        </Container>
    );

}


