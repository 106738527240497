import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'


function ChatControl() {

    return (

            <div class="row d-flex justify-content-center">
                <div class="col-12">

                    <div class="card " id="chat2">
                        <div class="card-header d-flex justify-content-between align-items-center p-3">
                            <h5 class="mb-0">Chat with Steve Parker</h5>
                        </div>

                    <div class="card-body chat-card">

                        <div class="d-flex flex-row justify-content-start">
                                <div className="from-avatar">DG</div>
                                <div>
                                    <p class="small p-2 ms-3 mb-1 rounded-3 from-message">Hi</p>
                                    <p class="small p-2 ms-3 mb-1 rounded-3 from-message" >How are you ...???
                                    </p>
                                    <p class="small p-2 ms-3 mb-1 rounded-3 from-message">What are you doing
                                        tomorrow? Can we come up a bar?</p>
                                    <p class="small ms-3 mb-3 rounded-3 text-muted">23:58</p>
                                </div>
                        </div>
                        <div class="d-flex flex-row justify-content-start">
                            <div className="from-avatar">DG</div>
                            <div>
                                <p class="small p-2 ms-3 mb-1 rounded-3 from-message">Hi</p>
                                <p class="small p-2 ms-3 mb-1 rounded-3 from-message" >How are you ...???
                                </p>
                                <p class="small p-2 ms-3 mb-1 rounded-3 from-message">What are you doing
                                    tomorrow? Can we come up a bar?</p>
                                <p class="small ms-3 mb-3 rounded-3 text-muted">23:58</p>
                            </div>
                        </div>


                        <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                            <div>
                                <p class="small p-2 me-3 mb-1 text-white rounded-3 to-message">Hiii, I'm good.</p>
                                <p class="small p-2 me-3 mb-1 text-white rounded-3 to-message">How are you doing?</p>
                                <p class="small p-2 me-3 mb-1 text-white rounded-3 to-message">Long time no see! Tomorrow
                                    office. will
                                    be free on sunday.</p>
                                <p class="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:06</p>
                            </div>
                            <div className="to-avatar">SP</div>
                        </div>


                    </div>

                    <div class="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                            <input type="text" class="form-control" id="exampleFormControlInput1"
                                placeholder="Type message"/>
                            </div>

                </div>





                </div>

            </div>
    );

}
export default ChatControl
