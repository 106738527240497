import { db } from './db';
import React, { Component, useState } from 'react';

import { LoggedIn, ReadLocal, WriteLocal, GetLastSyncDate, CreateGUID } from 'utils/LocalStorageHelper'


class Helper {


    //---- REST
    async httpDelete(url) {

        //-- we need to let the post know what typeof entity this is.
        let contents = { json: {}, success: false, errorMessage: "" }

        try {
            const requestOptions = {
                method: 'DELETE',
            };

            const response = await fetch(url, requestOptions);
            contents.success = response.ok;
            if (response.ok) {
                contents.json = await response.json();
            }

        }
        catch (err) {
            contents.errorMessage = "";
            contents.success = false;
            console.log(err);
        }

        return contents;
    }

    async httpPut(url, value) {

        //-- we need to let the post know what typeof entity this is.
        let contents = { json: {}, success: false, errorMessage: "" }

        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(value)
            };

            const response = await fetch(url, requestOptions);
            contents.success = response.ok;
            if (response.ok) {
                contents.json = await response.json();
            }

        }
        catch (err) {
            contents.errorMessage = "";
            contents.success = false;
            console.log(err);
        }

        return contents;
    }

    async httpPost(url, value) {

        //-- we need to let the post know what typeof entity this is.
        let contents = { json: {}, success: false, errorMessage: "" }

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(value)
            };

            const response = await fetch(url, requestOptions);
            contents.success = response.ok;
            if (response.ok) {
                contents.json = await response.json();
            }

        }
        catch (err) {
            contents.errorMessage = "";
            contents.success = false;
            console.log(err);
        }

        return contents;
    }

    async httpEntityPost(url, content, contentEntity, userId = 0) {


        //-- we need to let the post know what typeof entity this is.
        if (contentEntity) {
            content.contentEntity = contentEntity;
            //-- wtf :(
            if (contentEntity != "user") {
                content.userId = userId;
            }
        }

        let contents = { json: {}, success: false, errorMessage: "" }
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ entry: content })
            };

            const response = await fetch(url, requestOptions);
            contents.success = response.ok;
            if (response.ok) {
                contents.json = await response.json();
            }

        }
        catch (err) {
            contents.errorMessage = "";
            contents.success = false;
            console.log(err);
        }

        return contents;
    }

    async httpGet(url) {

        let contents = { json: {}, success: false, errorMessage : "" }

        try {
            if (navigator.onLine) {

                const response = await fetch(url);
                contents.success = response.ok;
                if (response.ok) {
                    contents.json = await response.json();
                }
            }
        }
        catch (err) {
            contents.errorMessage = "";
            contents.success = false;
            console.log(err);
        }

        return contents;

    }


    //-- upload a change made (insert/update/delete)
    async upload(item, dataset, pk, name, isNew) {

        const login = LoggedIn();

        if ((!pk && !item.clientGuid) || isNew) {
            if (!isNew) item.clientGuid = CreateGUID();

            if (isNew && !item.clientGuid) {
                throw "Trying to create a new entity without a clientId!";
            }

            item.created = new Date().toISOString();
            item.updated = new Date().toISOString();
            item.showId = login ? login.activeShowId : 1;

            item.deleted = 0;
            await db.addItem(item, dataset);
        }
        else {
            await db.upsertItem(item, dataset);
        }

        if (navigator.onLine) {
            console.log(item);
            var result = await this.httpEntityPost('/uploadentry', item, name, login ? login.user.userId : 0);
            if (!result.success) {
                //-- this didn't upload. Upsert it into the SyncLog
                await db.queueUpload(item, name, login.user.userId);
            }
        }
        else {
            await db.queueUpload(item, name, login.user.userId);
        }

        return "worked";

    }


    //---- ERRORS (ONLINE ONLY)
    async getErrors() {
        let getUrl = `/errorlogs`
        var result = await this.httpGet(getUrl);

        if (result.success) {
            return result.json;
        }
    }


    //---- TEMPLATES (ONLINE ONLY)
    async deleteTemplate(id) {
        let getUrl = `/contracttemplates/${id}`
        var result = await this.httpDelete(getUrl);

        if (result.success) {
            return result.json;
        }
    }

    async getTemplates() {
        let getUrl = `/contracttemplates`
        var result = await this.httpGet(getUrl);

        if (result.success) {
            return result.json;
        }
    }

    async uploadContractTemplate(template) {
        console.log("in")
        if (!navigator.onLine) return "cannot save when offline";

        if (template.ContractTemplateId) {
            var result = await this.httpPost('/contracttemplatesupload', template);
            if (!result.success) {
                return;
            }
        }
        else {
            var result = await this.httpPost('/contracttemplates', template);
            if (!result.success) {
                return;
            }
        }

        return "worked";

    }


    //---- GETS (note mostly done from UseLiveQuery)
    async getUserShowPack(userId) {

        let getUrl = `/usershowpack?userId=${userId}`
        var result = await this.httpGet(getUrl);

         if (result.success) {

             const ds = result.json;

             db.addItems(ds.products, db.products);
             db.addItems(ds.exhibitors, db.exhibitors);
             db.addItems(ds.contacts, db.contacts);
             db.addItems(ds.users, db.users);
             db.addItems(ds.shows, db.shows);
             db.addItems(ds.templates, db.templates);
             db.addItems(ds.contracts, db.contracts);
             db.addItems(ds.contractDetails, db.contractDetails);
             db.addItems(ds.notifications, db.notifications);
             db.addItems(ds.notifyAlerts, db.notifyalerts);
             db.addItems(ds.timelines, db.timelines);
             db.addItems(ds.messages, db.messages);
        }

    }


    //----- ----- SYNC METHODS ------

    async downloadWaiting(completed) {

        try {

            if (navigator.onLine) {

                const login = LoggedIn();
                if (!login) {
                    console.log("Cant upload when not logged in!");
                }

                const lastSync = GetLastSyncDate();
                const showId = login ? login.activeShowId : 1;
                const loginId = login.user.userId;

                let getUrl = `/downloadentries?showId=${showId}&loginId=${loginId}&updatedSince=${lastSync}`
                var encode = this.replaceAll(encodeURI(getUrl), ":", "%3A");

                var result = await this.httpGet(encode);

                if (result.success) {


                    if (result.json.exhibitors)
                        this.updateEntities(result.json.exhibitors, db.exhibitors, db.upsertItem);

                    //-- rewrite this to pass a function in
/*                    if (result.json.exhibitors && result.json.exhibitors.length > 0) {
                        const exhibitors = result.json.exhibitors;

                        for (var i = 0; i < exhibitors.length; i++) {
                            await db.updateItem(exhibitors[i], db.exhibitors);
                        }
                    }
*/
                    if (result.json.products)
                        this.updateEntities(result.json.products, db.products, db.upsertItem);

                    if (result.json.templates)
                        this.updateEntities(result.json.templates, db.templates, db.upsertItem);

                    if (result.json.contracts)
                        this.updateEntities(result.json.contracts, db.contracts, db.upsertItem);

                    if (result.json.contractDetails)
                        this.updateEntities(result.json.contractDetails, db.contractDetails, db.upsertItem);

                    if (result.json.notifications)
                        this.updateEntities(result.json.notifications, db.notifications, db.upsertItem);

                    if (result.json.messages)
                        this.updateEntities(result.json.messages, db.messages, db.upsertItem);

/*                    if (result.json.products && result.json.products.length > 0) {
                        const products = result.json.products;
    
                        for (var i = 0; i < products.length; i++) {
                            await db.updateItem(products[i], db.products);
                        }
                    }*/


                }

                completed();
                
            }
        }
        catch (err) {
            console.log("ERROR " + err);
            console.log("ERROR ERROR: an internal error ocurred whilst trying to get downloads");
            console.log("Forcing complete so this can retry");
            completed();

        }
    }

    async updateEntities(items, ds, updateMethod) {

        if (items && items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                await updateMethod(items[i], ds);
            }
        }
    }

    async uploadWaiting() {
        try {
            if (navigator.onLine) {

                const login = LoggedIn();
                if (!login) {
                    console.log("Cant upload when not logged in!");
                }

                const entries = await db.uploadsWaiting();

                for (var i = 0; i < entries.length; i++) {
                    let entry = entries[i];
                    var result = await this.httpEntityPost('/uploadentry', JSON.parse(entry.contents),
                        entry.entryType, login.user.userId);

                    if (result.success) {
                        await db.uploadSuccesful(entry.id);
                    }
                    else {
                        //-- upload didn't work :(
                    }

                }
            }
        }
        catch (err) {
            console.log("FAILED TO PROCESS UPLOAD: " + err);
        }

    }


    //------ HELPERS

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }
    

    render() {
        return (
            <></>
            )
    }


}

export const DataHelper = new Helper();



/*
db.on('populate', populate);
*.
 * 
export function resetDatabase() {
    return db.transaction('rw', db.todoLists, db.todoItems, async () => {
        await Promise.all(db.tables.map(table => table.clear()));
        await populate();
    });
}
*/