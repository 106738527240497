import React, { Component, useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'
import PageSpinner from 'components/ui/spinner/spinner'



export function Templates() {

    let navigate = useNavigate();
    const [templates, setTemplates] = useState();


    useEffect(() => {

        async function getTemplates() {
            const templates = await DataHelper.getTemplates();
            setTemplates(templates);
        }

        getTemplates();
        
    }, []);




    if(!templates) return <><PageSpinner/></>

    function newDocument() {
        navigate("/templateedit");
    }

    async function deleteDocument(template) {
        await DataHelper.deleteTemplate(template.contractTemplateId);
        const templates = await DataHelper.getTemplates();
        setTemplates(templates);
    }


    function editDocument(template) {
        navigate("/templateedit", { state: { template } });
    }



    return (
        <Container>
        <motion.div className="page-header-section"
        >
            <PageHeader pageName={"Contract Templates"} showTime={false} />
            <div className="row">
                <div className="col-md-4 col-xl-2">
                    <div className="card document-card" onClick={() => newDocument()} >
                        <div className="card-body document-body">
                            <div className="new-document"><i className="bi bi-file-earmark-plus"></i></div>
                        </div>
                        <div className="card-footer document-card-footer">
                            Create New
                        </div>
                    </div>
                </div>
                {templates?.map(item => (
                    <div className="col-md-4 col-xl-2" key={item.contractTemplateId}>
                            <div className="card document-card"  >
                                <div className="card-body document-body" onClick={() => editDocument(item)}>
                                    <img className="document-image" src={`data:image/jpeg;base64,${item.image}`} />
                                </div>
                                <div className="card-footer document-card-footer">
                                    {item.title} &nbsp; <button className="btn btn-light" onClick={() => deleteDocument(item)} ><i className="bi bi-trash"></i></button>
                                </div>
                            </div>
                        </div>
                        )) }

            </div>

            </motion.div>
        </Container>
    );

}


