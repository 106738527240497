import { ReadLocal } from 'utils/LocalStorageHelper'

export function roleClassName(className, roles) {

    let roleClass = className; 
    let login = ReadLocal(global.config.LOGIN_KEY);
    if (!login) return className;

    const user = JSON.parse(login).user;

    if (!roles.toLowerCase().includes(user.role.toLowerCase()))
    {
        roleClass = className + " invisible";
    }

    return roleClass;

}

export function setScrollOffset(pageName, position) {
    console.log(position);
    localStorage.setItem(pageName + "_scrollY", position);
}

export function getScrollOffset(pageName) {
    let scrollY = 0;
    try {
        scrollY = localStorage.getItem(pageName + "_scrollY");
    }
    finally {
    }
    return scrollY;
}