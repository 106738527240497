import React, { useState, Component } from 'react';
import logo from 'images/orbit-dark.png'
import { Route, useNavigate } from 'react-router-dom';
import Pulse from 'images/pulse.gif'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NetworkStatus from 'utils/NetworkStatus'
import { DataHelper } from 'utils/DataHelper';

import { WriteLocal, ReadLocal, WriteLastSyncDate } from 'utils/LocalStorageHelper'


export class LoginControl extends Component  {

    constructor(props) {
        super(props);
        this.state = { signingIn: false, username:'', password: '', loginFailed: false };

        this.handleCancelButton = this.handleCancelButton.bind(this)
        this.handleSignIn = this.handleSignIn.bind(this)
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

    }


    handleLoggedIn() {
        this.props.navHook('/dashboard')
    }

    handleCancelButton() {
        this.props.navHook('/')
    }

    handleSignIn() {
        this.setState({ signingIn: true, loginFailed: false });
        this.login();
    }

    handleUserNameChange(e) {
        this.setState({ username: e.target.value, loginFailed:false });
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value, loginFailed: false
        });
    }

    render() {

        const signingIn = this.state.signingIn;
        const loginFailed = this.state.loginFailed;

        return (

            <section className="jumbotron text-center jumbo-login anim-fade">

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <ToastContainer />

                <div className="container">
                    <div className="row">
                        <div className="offset-md-3 col-md-6">
                            <div className="card no-border">
                                <div className="card-body card-pad">
                                    <div className="card-text">
                                        <img src={logo} className={"App-logo-login"} alt="logo" />
                                        <h3>Orbit Login</h3>
                                        <div className="row">
                                            <input type="email" onChange={this.handleUserNameChange} disabled={signingIn} id="inputEmail" className="col-12 form-control input-pad" placeholder="Email address" required autoFocus />
                                        </div>
                                        <div className="row">
                                            <input type="password" onChange={this.handlePasswordChange} disabled={signingIn} id="inputPassword" className="col-12 form-control input-pad" placeholder="Password" required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <button type="button" className="btn btn-light btn-pad" onClick={this.handleCancelButton} >Cancel <i className="bi bi-x"></i></button>
                                    </div>
                                    <div className="row">
                                        <button type="button" disabled={signingIn} className="btn btn-primary btn-pad" onClick={this.handleSignIn}>Sign In <i className="bi bi-box-arrow-in-right"></i> </button>
                                    </div>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        {!signingIn &&
                                            <Link to="/forgot">I forgot my password</Link>
                                        }
                                    </div>
                                    <p style={{ marginTop: "12px" }}>&nbsp; {signingIn && <span>Signing In <img src={Pulse} style={{ width: "30px" }} /></span>}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        );
    }

    failedLogin(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    async login() {
        const username = this.state.username;
        const password = this.state.password;

        let getUrl = `/users?userName=${username}&password=${password}`

        try {
            if (navigator.onLine) {

                const response = await fetch(getUrl);

                if (!response.ok) {
                    this.setState({ loginFailed: true });
                    if (response.status == 404) {
                        //window.alert("Login failed.. please check your username and password");
                        //-- unknown user or login failed.
                        this.failedLogin('Your login Login failed. Please check username and password..');
                        return;
                    }
                    return;
                }

                let data = await response.json();

                await DataHelper.getUserShowPack(data.user.userId).then(WriteLastSyncDate());

                WriteLocal(global.config.LOGIN_KEY, JSON.stringify(data));
                WriteLocal(global.config.LOGIN_CACHE + username, JSON.stringify(data));

                this.handleLoggedIn();
            }
            else {
                var local = ReadLocal(global.config.LOGIN_CACHE + username);
                if(local) local = JSON.parse(local);

                if (local && local.user.userPassword.toLowerCase() == password.toLowerCase()) {
                    WriteLocal(global.config.LOGIN_KEY, JSON.stringify(local));
                    this.handleLoggedIn();
                }
                else {
                    this.failedLogin('Failed to login offline. (note: You must have logged in at least once online before). please check username and password ');
                }

            }

            //        setData(actualData);
            //        setError(null);
        } catch (err) {
            console.log(err);
            this.setState({ loginFailed: true });

            //        setError(err.message);
            //        setData(null);
        } finally {
            this.setState({ signingIn: false });
            //        setLoading(false);
        }

    }



}

function navParams(Component) {
    return props => <Component navHook={useNavigate()} />;
}


export default navParams(LoginControl);



