import React, { Component, useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button } from 'react-bootstrap'
import { motion } from 'framer-motion'
import  PageSpinner  from 'components/ui/spinner/spinner'
import { db } from 'utils/db'
import { useNavigate, useLocation } from "react-router-dom";
import { RemoveLocal, ReadLocal } from 'utils/LocalStorageHelper'
import ReactDOMServer from "react-dom/server";
import _, { forEach } from 'lodash';
import ProductsModal from 'components/modal/ProductsModal'
import SignatureModal from 'components/modal/SignatureModal'
import { CreateGUID } from 'utils/LocalStorageHelper'
import { ToastContainer, toast } from 'react-toastify';
import { getHumanFriendlyDateString } from 'utils/DateHelper'



export function ContractPage() {

    let navigate = useNavigate();

    const [signatureUrl, setSignatureURL] = useState()

    const [products, setProducts] = useState()
    const [isSaving, setIsSaving] = useState(false)

    const location = useLocation();
    const templates = useLiveQuery(() => db.templates.where('tableGuid').equals(15).toArray());
    const [contacts, setContacts] = useState();

    const exhibitor = location.state.exhibitor;
    const login = JSON.parse(ReadLocal(global.config.LOGIN_KEY));
    const elementRef = useRef()

    const [contract, setContract] = useState({})
    const [contractDetails, setcontractDetails] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [isNew, setIsNew] = useState(true)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });


    useEffect(() => {
        function handler(event) {

            let target = event.target;
            if (target.parentElement.tagName == 'TD') target = target.parentElement;

            if (!target.offsetParent) return;

            _.forEach(target.offsetParent.classList, function (item) {
                if (item.toString() == 'table-contract') {
                    dialogShow();
                }

                if (item.toString() == 'table-signature') {
                    dialogSignatureShow();
                }

            })
        }

        window.addEventListener('click', handler)

        async function getContactData() {
            let productList = await db.products.toArray();
            let contactList = await db.contacts.toArray();

            await getContract(productList, contactList);
            setProducts(productList);
            setContacts(contactList);
        }
        getContactData();


        return () => window.removeEventListener('click', handler)
    }, [])

    if (!templates || !products || !login || !contract || !contacts) return <><PageSpinner /></>;

    const template = JSON.parse(templates[0].contents);


    function toggleModal() {
        setShowModal(!showModal);
    }

    function toggleSignatureModal() {
        setShowSignatureModal(!showSignatureModal);
    }

    function dialogSignatureShow() {

        if (exhibitor.rebookStatus == 'S') {
            showToast('This is a signed contract. It cannot be changed.')
            return;
        }
        setShowSignatureModal(true);
    }


    function dialogShow() {

        if (exhibitor.rebookStatus == 'S') {
            showToast('This is a signed contract. It cannot be changed.')
            return;
        }

        setShowModal(true);
    }

    function updateContractMarkUp() {
        const markUp = processTemplate();
        const value = calculateTotal();

        let data = contract.details;

        data.markUp = markUp;
        data.value = value;

        setContract({ details: data })

        return true;
    }

    function updateProducts(editedProducts) {
        setcontractDetails(editedProducts);
        updateContractMarkUp();
    }

    function productIsValid(product) {

        if (!product.validityFormula) return true;

        let sponsorType = exhibitor.sponsorType.toUpperCase();
        let valid = false;

        eval("valid = " + product.validityFormula);

        return valid;

    }

    function getSignatory(contractId) {

        if (!contractId) return null;

        let _contacts = _.filter(contacts, { tableGuid: contractId });
        if (!_contacts || _contacts.length == 0) return null;

        return JSON.parse(_contacts[0].contents);

    }

    function getContacts(_contacts) {

        let exhibitorContacts = [];
        for (let i = 0; i < _contacts.length; i++) {
            const contact = JSON.parse(_contacts[i].contents);

            if (contact.exhibitorId == exhibitor.exhibitorId) {
                exhibitorContacts.push(contact);
            }
        }
        return exhibitorContacts;
    }


    async function getContract(productListing, contactList) {

        let _contracts = await db.contracts.toArray();
        let details = null;

        _contracts.forEach((item) => {
            let _info = JSON.parse(item.contents);
            if (_info.exhibitorId == exhibitor.exhibitorId && !details) {
                details = _info;
            }
        });

        if (details) {

            let _contract = {
                details
            };

            setContract(_contract);
            let _details = await db.contractDetails.toArray();

            let _contractDetails = [];

            _details.forEach((item) => {
                let cd = JSON.parse(item.contents);

                if (cd.contractGuid == details.clientGuid) {

                    let products = _.filter(productListing, { tableGuid: cd.productId });

                    if (products && products.length > 0) {

                        let product = JSON.parse(products[0].contents);

                        _contractDetails.push({
                            contractId: cd.contractId,
                            lineNo: cd.lineNo,
                            productId: product.tableGuid,
                            value: cd.value,
                            title: cd.title,
                            area: cd.area,
                            deleted: cd.deleted,
                            productType: cd.productType,
                            rebookText: exhibitor.rebookText,
                            standNumber: exhibitor.standNumber,
                            description: product.description,
                            sortOrder: product.sortOrder,
                            productType: product.productType,
                            isLocked: product.isLocked,
                            description: product.description,
                            contractGuid: details.clientGuid,
                            clientGuid: cd.clientGuid
                        })

                    }
                }
            });

            setcontractDetails(_contractDetails);

            setIsNew(false);
        }

        if (!details) {
            setIsNew(true);
            await createDefaultContract(productListing, contactList)
        }

    }


    async function createDefaultContract(productListing, contactList) {

        let guid = CreateGUID();
        let contractId = null
        let contactId = null

        let _contacts = getContacts(contactList);
        console.log(_contacts);
        if (_contacts && _contacts.length > 0) {
            contactId = _contacts[0].contactId;
        }


        //-- if this is not a new contract, use the existing clientGuid
        if (isNew === false) {
            contractId = contract.details.contractId
            contactId = contract.details.contactId;
            guid = contract.details.clientGuid;
        }
        console.log(login);

        const defaultContract = {
            details: {
                contractId: contractId,
                exhibitorName: exhibitor.exhibitorName,
                showName: "Enterprise Connect 2024",
                reference: "EC24-" + guid.substring(0, 5),
                title: exhibitor.exhibitorName + '  contract ' + exhibitor.standNumber, status: "D",
                clientGuid: guid,
                contactId: contactId,
                exhibitorId: exhibitor.exhibitorId,
                showId: exhibitor.showId,
                standNumber: exhibitor.standNumber,
                preparedBy: login.user.fullName,
                preparedDate: new Date().toISOString(),
                userId: login.user.userId,
                markUp: null,
                status: 'D',
                value: 0
            }
        };

        const defaultProducts = [];

        //-- resetting a contract means existing items are deleted.
        if (isNew === false) {
            contractDetails.forEach((item) => {
                item.deleted = 1;
                defaultProducts.push(item);
            });
        }


        let lineNo = 0;

        const preloadedProducts = getPreloadedProducts(productListing);

        var spaceProducts = _.filter(preloadedProducts, { 'productType': 'SPACE' });

        _.forEach(spaceProducts, function (product) {

            const guid = CreateGUID();

            if (productIsValid(product)) {
                let value = productPrice(product);
                lineNo = lineNo + 1;
                defaultProducts.push({ lineNo: lineNo,
                    productId: product.tableGuid, value: value, title: product.title, area: product.area,
                    productType: product.productType, rebookText: exhibitor.rebookText, standNumber: exhibitor.standNumber,
                    description: product.description, sortOrder: product.sortOrder, isLocked: product.isLocked,
                    contractGuid: defaultContract.details.clientGuid , clientGuid: guid, deleted: 0
                })
            }

        })

        _.forEach(preloadedProducts, function (product) {

            if (product.productType != 'SPACE' && productIsValid(product)) {
                let value = productPrice(product);
                const guid = CreateGUID();

                lineNo = lineNo + 1;
                defaultProducts.push({
                    lineNo: lineNo,
                    productId: product.tableGuid, value: value, title: product.title, area: product.area, isLocked: product.isLocked, productType: product.productType,
                    description: product.description, sortOrder: product.sortOrder, isLocked: product.isLocked,
                    contractGuid: defaultContract.details.clientGuid, clientGuid: guid, deleted: 0
                })
            }
        })

        console.log(defaultContract.details.contactId);
        setContract(defaultContract);
        setcontractDetails(defaultProducts);

    }

    function productListingHeader() {
        return (
            <tr>
                <th style={{ width: "70%" }}>Product</th>
                <th style={{ width: "30%", textAlign:"right" }}>Total</th>
            </tr>
            )
    }

    function calculateTotal() {
        let grandTotal = 0;
        _.forEach(contractDetails, function (item) {
            if(item.deleted == 0) grandTotal = grandTotal + item.value;
        });
        return grandTotal;
    }

    function productTotalHeader() {

        let grandTotal = calculateTotal();

        return (
            <tr style={{ border: "none !important" } }>
                <th style={{ width: "70%", textAlign: "right", padding: "6px", border: "none !important" }}>Grand Total</th>
                <th style={{ width: "30%", textAlign: "right", padding: "6px", border: "none !important" }}>{formatter.format(grandTotal)}</th>
            </tr>
        )
    }


    function getPreloadedProducts(productListing) {

        const PreloadedProducts = []

        for (let i = 0; i < productListing.length; i++) {
            const product = JSON.parse(productListing[i].contents);
            if (product.isPreloaded == 1 && product.deleted == 0) {
                PreloadedProducts.push(product);
            }
        }
        return PreloadedProducts;
    }

    function productPrice(product) {

        let value = 0;

        let sponsorType = product.sponsorType;
        let area = exhibitor.rebookWidth * exhibitor.rebookBredth;
        let unitPrice = product.unitPrice;

        try {
            eval("value " + product.unitFormula);
        }
        catch (err) {
            value = "?? invalid formula " + product.unitFormula;
        }

        return value;

    }


    function productListingBody() {

        const space = _.filter(contractDetails, { 'productType': 'SPACE', deleted: 0 });
        const other = _.filter(contractDetails, function(i){ return i.productType != 'SPACE' && i.deleted==0 });

        const spaceProducts = _.sortBy(space, ['sortOrder'], ['asc']);
        const otherProducts = _.sortBy(other, ['sortOrder'], ['asc']);

        return (
            <>
                {spaceProducts.map(item => (
                    <tr key={item.lineNo}>
                        <td data-entry="PRODUCT"><span style={{ fontWeight: "800" }}>{item.title}</span> <small>{exhibitor.rebookText} - {item.standNumber}</small>
                            {item.description && item.description.length > 0 ? <span><br /><small>{item.description}</small></span>  : <></> }
                        </td>
                        <td style={{ width: "20%", textAlign: "right" }}>{formatter.format(item.value)}</td>
                    </tr>
                ))}
                {otherProducts.map(item => (
                    <tr key={item.lineNo}>
                        <td data-entry="PRODUCT"><span style={{ fontWeight: "800" }}>{item.title}</span>
                            {item.description && item.description.length > 0 ? <span style={{fontSize:"12px"}}><br />{item.description}</span> : <></>}
                        </td>
                        <td  style={{ width: "20%", textAlign: "right" }}>{formatter.format(item.value)}</td>
                    </tr>
                ))}
            </>
        )
    }

    function productSummaryListing() {
        const space = _.filter(contractDetails, { 'productType': 'SPACE', deleted: 0 });
        const other = _.filter(contractDetails, function (i) { return i.productType != 'SPACE' && i.deleted ===0 });

        const spaceProducts = _.sortBy(space, ['sortOrder'], ['asc']);
        const otherProducts = _.sortBy(other, ['sortOrder'], ['asc']);

        return (
            <ul>
                {spaceProducts.map(item => (
                    <li key={item.lineNo}>{item.title} - Booth# {item.standNumber}</li>
                ))}
                {otherProducts.map(item => (
                    <li key={item.lineNo}>{item.title}</li >
                ))}
            </ul>
        )

    }

    function productListing() {
        return (
            <>
                <table style={{ width: "100%" }} className="table-contract contract-editable">
                    {productListingHeader()}
                    {productListingBody()}
                </table>
                <table style={{ width: "100%", marginTop: "20px", border: "none !important", padding: "6px" }}>
                    {productTotalHeader()}
                </table>
            </>
        )
    }

    function checkBox() {
        return (
            <input type="checkbox" />
        )
    }

    function signatureBox() {
        return (
            <table className="table-signature contract-editable" style={{ width: "200px", height: "100px" }} >
                <tr>
                    <td className="signature-box">
                        {signatureUrl ? <img src={signatureUrl} alt="signature" style={{ width:"100px", height:"50px"} } />
                            :
                            <span>signature x</span>
                        }
                    </td>
                </tr>
            </table>
            )
    }

    function signatoryBox() {

        console.log(contract.details.contactId);
        let contact = getSignatory(contract.details.contactId);

        let fullName = 'No Signatory.. Please assign...'
        let position = ''
        let email = ''
        let phone = ''

        if (contact) {
            fullName = contact.fullName
            position = contact.position;
            email = contact.email;
            phone = contact.phone;
        }

        return (
            <table style={{ width: "100%", fontSize: "14px" }} className="table-contact contract-editable">
                <tr>
                    <td><b>{fullName}</b> - <i>{position}</i> <br /> {email} {phone} </td>
                </tr>
            </table>
            )
    }



    function processTemplate() {

        console.log(contract);
        if (exhibitor.rebookStatus == 'S') {
            let markUp = contract.details.markUp;
            if (!markUp || markUp.length == 0) {
                return "<h3 style='margin-top:30px;'>Warning: Markup has been lost :( </h3>";
            }
            markUp = markUp.replace(new RegExp('contract-editable', 'g'), 'non-editable');
            return markUp;
        }

        var date = new Date();
        var n = date.toDateString();
        var t = date.toLocaleTimeString();
        var productList = ReactDOMServer.renderToStaticMarkup(productListing());
        var box = ReactDOMServer.renderToStaticMarkup(checkBox());
        var productSummary = ReactDOMServer.renderToStaticMarkup(productSummaryListing());
        var signatory = ReactDOMServer.renderToStaticMarkup(signatoryBox());
        var signature = ReactDOMServer.renderToStaticMarkup(signatureBox());

        let contents = template.contents;
        contents = contents.replace(/##ExhibitorName/g, contract.details.exhibitorName);
        contents = contents.replace(/##ShowName/g, contract.details.showName);
        contents = contents.replace(/##SalesPerson/g, contract.details.preparedBy);
        contents = contents.replace(/##Date/g, n);
        contents = contents.replace(/##ContractRef/g, contract.details.reference);
        contents = contents.replace(/##Time/g, t);
        contents = contents.replace(/##PRODUCTS/g, productList);
        contents = contents.replace(/##CHECKBOX/g, box);
        contents = contents.replace(/##SUMMARY/g, productSummary);
        contents = contents.replace(/##SIGNATORY/g, signatory);
        contents = contents.replace(/##SIGNATURE/g, signature);
        contents = contents.replace(/<hr>/g, "<div className='contract-page-break'></div>");

        return contents;
    }



    async function saveContract(isCancelled = false) {

        updateContractMarkUp();
        setIsSaving(true);
        let item = contract.details

        //-- if this is an update - send the Id
        //if (location.state && location.state.item.product) {
        //    item.productId = product.productId;
        //}
        console.log(item.contractId);
        console.log(isNew);

        if (isCancelled) {
            exhibitor.rebookStatus = 'C';
        }
        else {
            if (signatureUrl) {
                exhibitor.rebookStatus = 'S';
            }
            else {
                exhibitor.rebookStatus = 'D';
            }
        }


        item.status = exhibitor.rebookStatus;
        item.deleted = 0;

        let result = await DataHelper.upload(exhibitor, db.exhibitors, exhibitor.exhibitorId, "exhibitor");

        if (result) {
            result = await DataHelper.upload(item, db.contracts, item.contractId, "contract", isNew);
        }

        for (let i = 0; i < contractDetails.length;i++) {
            if (result) {
                const c = contractDetails[i];

                let area = exhibitor.rebookWidth * exhibitor.rebookBredth;

                const item = {
                    contractId: c.contractId,
                    area: area,
                    lineNo: c.lineNo,
                    productId: c.productId,
                    showId: exhibitor.showId,
                    title: c.title,
                    value: c.value,
                    deleted: c.deleted,
                    reference: contract.details.reference,
                    tableGuid: c.tableGuid,
                    clientGuid: c.clientGuid,
                    contractGuid: c.contractGuid
                }
                console.log(item);

                result = await DataHelper.upload(item, db.contractDetails, item.contractDetailsId, "contractdetail", isNew);
            }
        }

        if (result) {
            showToast("Saved Contract");
        }
        else {
            showToastError("Failed to save contract");
        }
        setIsSaving(false);

    }

    function goBack() {
        navigate("/exhibitorlisting")
    }
    

    async function resetContract() {
        setSignatureURL(null);
        exhibitor.rebookStatus = 'D';
        await createDefaultContract(products, contacts);
        showToast("Reset contract back to defaults");
    }

    async function resignContract() {
        setSignatureURL(null);
        exhibitor.rebookStatus = 'D';
    }


    function assignSignature(dataUrl) {
        setSignatureURL(dataUrl);
    }

    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function showToast(message) {
        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    function isNotCancelled() {
        return exhibitor.rebookStatus != 'C'
    }

    function isEditableContract() {

        return exhibitor.rebookStatus == 'D' || exhibitor.rebookStatus == 'W'

    }

    const contractStatus = () => {

        let dated = "";
        let preparedBy = "";
        console.log(contract.details.updated);

        if (contract.details.created) {
            dated = getHumanFriendlyDateString(contract.details.created)
        }

        if (contract.details.updated) {
            dated = getHumanFriendlyDateString(contract.details.updated)
        }

        if (contract.details.preparedBy) {
            preparedBy = "(" + contract.details.preparedBy + ")";
        }


        switch (exhibitor.rebookStatus) {
            case 'D':
                return (
                    <span className="badge bg-light col-auto status-badge-large status-draft">Draft created {dated} </span>
                )
            case 'W':
                return (
                    <></>
                )
            case 'S':
                return (
                    <span className="badge bg-light col-auto status-badge-large status-signed">Signed {dated} {preparedBy}</span>
                )
            case 'C':
                return (
                    <span className="badge bg-light col-auto status-badge-large status-cancelled">Cancelled {dated} {preparedBy}</span>
                )
        }

    }


    return (
        <motion.div
            className="page-header-section"
        >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <PageHeader pageName={"Contract"} showTime={false} />

            {exhibitor.rebookStatus == 'D' && <div className="contract-draft">DRAFT CONTRACT</div>}
            {exhibitor.rebookStatus == 'C' && <div className="contract-cancelled"><i className="bi bi-x-circle-fill"></i> CANCELLED CONTRACT</div>}
            {exhibitor.rebookStatus == 'S' && <div className="contract-signed"><i className="bi bi-pen"></i> SIGNED CONTRACT</div>}

            <div className="contract-menu">
                <div className="row">
                    <button className="btn btn-dark col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => goBack()}><i className="bi bi-arrow-left-circle"></i></button>
                    {(isNotCancelled()) && <button className="btn btn-danger col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => saveContract(true)}><i className="bi bi-x-circle-fill"></i> Cancel </button>}
                    {(isEditableContract() && !signatureUrl) && <button className="btn btn-light col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => saveContract()}><i className="bi bi-save"></i> Save Draft</button>}
                    {(isEditableContract() && signatureUrl) && <button className="btn btn-primary col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => saveContract()}><i className="bi bi-save"></i> Finalise</button>}
                    <button className="btn btn-light col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => resetContract()}><i className="bi bi-recycle"></i> Reset</button>
                    {exhibitor.rebookStatus == 'S' && <button className="btn btn-light col-auto contract-menu-button btn-border" disabled={isSaving} onClick={() => resignContract()}><i className="bi bi-pen"></i> Re-sign</button>}
                    { contractStatus() }
                </div>
            </div>

            {isSaving && <PageSpinner isDark={true} /> }

            <div className="contract-page">
                <div className="contract-contents" key={Date()} ref={elementRef} dangerouslySetInnerHTML={{ __html: processTemplate() }}></div>
            </div>

            <ProductsModal onUpdateProducts={updateProducts} onClose={toggleModal} key={100} show={showModal} products={contractDetails}>
            </ProductsModal>

            <SignatureModal onClose={toggleSignatureModal} key={101} show={showSignatureModal} setSignature={assignSignature}>
            </SignatureModal>

        </motion.div>
    );

}



