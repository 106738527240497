import React, { useState, useEffect, useRef} from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FloorPlanHelper } from "utils/PlanHelper"
import PageSpinner from 'components/ui/spinner/spinner'
import { db } from "utils/db";
import { useLiveQuery } from "dexie-react-hooks";



function FloorplanControl() {

    const [floorplan, setFloorplan] = useState();
    const exhibitors = useLiveQuery(() => db.exhibitors.where("deleted").equals(0).toArray());

    const canvas = useRef();
    let ctx = null;

    useEffect(() => {

        async function getFloorplan() {
            var plan = await FloorPlanHelper.readFloorplan();
            setFloorplan(plan);
        }

        getFloorplan();

    }, []);

    if (!floorplan && !exhibitors) return <><PageSpinner /></>;

    const standscale = 0.075;


    const drawPolygon = (points, textData, list) => {

        if (!points || points.length == 0) return;

        if (!textData || textData.length == 0) {

            ctx.fillStyle = '#eee';

        }
        else {
            const entry = list.find(x => x.standNumber == textData[0].v);

            if (entry) {
                switch (entry.rebookStatus) {
                    case "C":
                        ctx.fillStyle = '#DD0000';
                        break;
                    case "W":
                        ctx.fillStyle = '#FAFAFA';
                        break;
                    case "S":
                        ctx.fillStyle = '#00CC00';
                        break;
                    default:
                        ctx.fillStyle = '#fafafa';
                }
            }
            else
                ctx.fillStyle = '#eee';

         
        }


        ctx.beginPath();
        ctx.moveTo(points[0].x * standscale, (points[0].y * standscale) -150);

        points.forEach((point) => {
            ctx.lineTo(point.x * standscale, (point.y * standscale) -150);
        })
        ctx.closePath();
        ctx.fill();

    }

    const drawLine = (info, style = {}) => {
        let { x, y, x1, y1 } = info;
        const { color = 'black', width = 1 } = style;

        x *= standscale;
        y *= standscale;
        x1 *= standscale;
        y1 *= standscale;

        y -= 150;
        y1 -= 150;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x1, y1);
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
        ctx.stroke();
    }

    const drawText = (info, style = {}) => {
        let { x, y, s, v } = info;
        const { color = 'black', width = 1 } = style;



        x *= standscale;
        y *= standscale;
        y -= 150;

        ctx.fillStyle = color;
        ctx.font = (s * standscale ) + "px Arial";
        ctx.textAlign = "center";
        ctx.fillText(v, x, y);

    }

    function getExhibitorList() {

        let data = [];

        if (!exhibitors || exhibitors.length == 0) return data;

        exhibitors.forEach((exhibitor) => {
            let c = JSON.parse(exhibitor.contents);
            data.push({ rebookStatus: c.rebookStatus, standNumber: c.standNumber });
        });
        return data;

    }


    function drawPlan() {


        if (!canvas.current || !floorplan) return null;

        const exhibitorList = getExhibitorList();

        const canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height =600;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");

        for (let i = 0; i < floorplan.length; i++) {
            let entry = floorplan[i];
            let points = [];
            let first = null;
            let last = null;
            let textData = [];

            for (let p = 0; p < entry.children.length; p++) {

                let item = entry.children[p];
                if (item.name == 'P') {
                    if (!first) first = item;
                    points.push({ x: item.attributes.x, y: item.attributes.y });
                    last = item;
                }

                if (item.name == 'T') {
                    textData.push({ x: item.attributes.x, y: item.attributes.y, v: item.attributes.v, s: item.attributes.s });
                }

            }

            if (first && last) {
                points.push({ x: last.attributes.x, y: last.attributes.y });
                points.push({ x: first.attributes.x, y: first.attributes.y });
            }

            drawPolygon(points, textData, exhibitorList);

            for (let c = 0; c < points.length - 1; c++) {
                const pt1 = points[c];
                const pt2 = points[c+1];
                drawLine({ x: pt1.x, y: pt1.y, x1: pt2.x, y1: pt2.y });
            }

            for (let t = 0; t < textData.length; t++) {
                drawText(textData[t]);
            }

        }


    }


    return (
        <>
            <canvas ref={canvas}></canvas>
            {drawPlan()}
        </>
    );
 
}
export default FloorplanControl