
module.exports = global.config = {

    LOGIN_KEY : "login",
    LOGIN_CACHE: "logincache-",
    SYNC_FREQUENCY_SECS: 1,
    DRAFT_TEMPLATE: "draft-template",
    LAST_SYNC: "last-sync",

    USE_SPLASH: true,
    SPLASH_DELAY: 3000,

    storagekeys: {
        login : "login"
    }
};