import React, { Component, useState, useEffect } from "react";
import  RequestAccessControl  from 'components/form/RequestAccessControl'

function RequestAccess() {

    return (
        <div id="pageHolder">
            <div id="pageContent">
                <main role="main">
                    <RequestAccessControl />
                </main>
            </div>
        </div>
    );

}

export default RequestAccess;