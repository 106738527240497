import React, { Component, useState, useEffect } from 'react';
import { RemoveLocal, ReadLocal } from 'utils/LocalStorageHelper'
import { Route, Routes, useNavigate } from 'react-router-dom';
import PageSpinner from 'components/ui/spinner/spinner'
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import  ChatControl  from 'components/form/ChatControl'




function Sidebar(props) {

    const [loggedIn, setLoggedIn] = useState(false);
    const [login, setLogin] = useState({});

    const [filteredResults, setFilteredResults] = useState([]);

    const messages = useLiveQuery(() => db.messages.where("deleted").equals(0).toArray());
    const users = useLiveQuery(() => db.users.where("deleted").equals(0).toArray());

    useEffect(() => {
        let key = ReadLocal(global.config.LOGIN_KEY);

        if (key) {
            setLogin(key);
            setLoggedIn(true);
        }
        else {
            setLogin({});
            setLoggedIn(false);
        }

    }, []);

    if (!messages || !users) return <><PageSpinner /></>;

    function handleLogoutButton() {
        //-- Logout..
        RemoveLocal(global.config.LOGIN_KEY);
        props.handleSideBarClose('/');
        setLoggedIn(false);
    }


    return (
        <>
            {loggedIn === false ?
                <div>
                    <p><b>you are currently not logged in.</b></p>
                    <i className="bi bi-info-circle"></i>&nbsp;You need to be logged in before you can perform any activities.<br /><br />
                </div>
                : 
                <>
                </>
            }

            {loggedIn === true ?
                <ChatControl />
                :
                <>
                </>
            }

            <button type="button" className="nav-link btn-border btn-sidemenu btn-side-logout" onClick={handleLogoutButton} >Logout <i className="bi bi-x"></i></button>
            <button type="button" className="nav-link btn-border btn-sidemenu btn-side-settings" onClick={handleLogoutButton} ><i className="bi bi-gear"></i></button>

        </>
    );
}

export default Sidebar;

/*
class Sidebar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loggedIn: false,
            login: {}
        };

        this.showReception = this.showReception.bind(this);
        this.showDashboard = this.showDashboard.bind(this);
        this.showTemplates = this.showTemplates.bind(this);
        this.showProducts = this.showProducts.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.handleLogoutButton = this.handleLogoutButton.bind(this);

    }

    componentDidMount() {

        let login = ReadLocal(global.config.LOGIN_KEY);
        if (login) {
            try {
                this.setState({ loggedIn: true, login: JSON.parse(login) })
            }
            catch (err) {
                console.log("login parse object failed..");
            }
        }
    }

    showReception() {
        this.props.handleSideBarClose('/reception');
    }


    showDashboard() {
        this.props.handleSideBarClose('/dashboard');
    }


    showTemplates() {
        this.props.handleSideBarClose('/templates');
    }

    showErrors() {
        this.props.handleSideBarClose('/servererrors');
    }

    showProducts() {
        this.props.handleSideBarClose('/productlisting');
    }


    handleLogoutButton() {
        //-- Logout..
        RemoveLocal(global.config.LOGIN_KEY);
        this.setState({ loggedIn: false })
        this.props.handleSideBarClose('/');
    }

    loggedIn() {
        var login = this.state.login.user;
        return <>

            <div className="d-flex flex-column flex-shrink-0 p-3">
                    <ul className="nav nav-pills flex-column mb-auto">
                        <li className="nav-item">
                            <button type="button" className="nav-link btn-border btn-sidemenu" onClick={this.showDashboard} >Dashboard <i className="bi bi-menu-button-wide-fill"></i></button>
                        </li>
                        <li className="nav-item">
                            <button type="button" className="nav-link btn-border btn-sidemenu" onClick={this.showReception} >Appointments <i className="bi bi-ui-checks"></i></button>
                        </li>
                        <li className="nav-item">
                            <button type="button" className="nav-link btn-border btn-sidemenu" onClick={this.showTemplates} >Templates <i className="bi bi-file-earmark-medical"></i></button>
                        </li>
                    <li className="nav-item">
                        <button type="button" className="nav-link btn-border btn-sidemenu" onClick={this.showProducts} >Products <i className="bi bi-bag"></i></button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="nav-link btn-border btn-sidemenu" onClick={this.showErrors} >Errors <i className="bi bi-circle-fill"></i></button>
                    </li>
                </ul>

                <button type="button" className="nav-link btn-border btn-sidemenu btn-side-logout" onClick={this.handleLogoutButton} >Logout <i className="bi bi-x"></i></button>
                <button type="button" className="nav-link btn-border btn-sidemenu btn-side-settings" onClick={this.handleLogoutButton} ><i className="bi bi-gear"></i></button>

            </div>

        </>
    }

    notLoggedIn() {
        return (
            <>
                <p><b>you are currently not logged in.</b></p>
                <i className="bi bi-info-circle"></i>&nbsp;You need to be logged in before you can perform any activities.<br /><br />
            </>
        )
    }

    render() {

        const loggedIn = this.state.loggedIn;

        const content = loggedIn ? this.loggedIn() : this.notLoggedIn();
        return (
            <>
                {content}
            </>
       )
    }

}


export default Sidebar;
*/




/*
 * 
             <p><strong>{login.fullName}</strong> <span className="badge bg-dark">{login.role}</span></p>
            <button type="button" className="btn btn-primary" onClick={this.handleLogoutButton} >Logout <i className="bi bi-x"></i></button>
            <button type="button" className="btn btn-success" onClick={this.showReception} >Reception <i className="bi bi-ui-checks"></i></button>
            <button type="button" className="btn btn-info" onClick={this.showDashboard} >Dashboard <i className="bi bi-menu-button-wide-fill"></i></button>

*/