import React, { Component, useState, useEffect, useRef } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Button, Container } from 'react-bootstrap'
import { Label } from "reactstrap";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import { UserInitials } from 'utils/LocalStorageHelper'
import PageSpinner from 'components/ui/spinner/spinner'
import { Form } from 'react-bootstrap';


function RequestAccessControl() {
    const [thanks, setThanks] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [user, setUser] = useState({});
    const [isSaving, setIsSaving] = useState(false);


    const navigate = useNavigate();


    function showToastError(message) {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }


    const saveuser = async () => {

        let item = user;
        item.title = user.fullName
        item.deleted = 0;
        item.activeShowId = 1;
        item.role = "Unassigned";
        setIsSaving(true);
        const result = await DataHelper.upload(item, db.users, item.userId, "user");

        if (result) {
            setIsSaving(false);
            setThanks(true);
        }
        else {
            setIsSaving(false);
            showToastError("Failed to save - this looks like an app fault :(");
        }

    }

    const updateFullName = (e) => { setUser({ ...user, fullName: e.target.value }); };
    const updateUserName = (e) => { setUser({ ...user, userName: e.target.value }); };
    const updatePassword = (e) => { setUser({ ...user, userPassword: e.target.value }); };
    const updateEmail = (e) => { setUser({ ...user, email: e.target.value }); };
    const updatePhone = (e) => { setUser({ ...user, phone: e.target.value }); };

    function handleCancelButton() {
        navigate('/')
    }

    function thanksBody() {
        return (
            <Container>
            <div className="card-body" style={{marginTop:"100px"} }>
                <div className="row">
                    <div className="col-12">
                            <h5>Thanks for your interest!</h5>
                            <p>We will review your request for an account and message you back on your email and text number provided.</p>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={() => handleCancelButton()}>Close <i className="bi bi-x"></i></button>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </Container>
            )
    }

    function requestBody() {
        return (
            <Container>

            <div className="card-body">


                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-6 col-12" style={{ marginTop: "80px" }}>
                        <div className="row">
                            <div className="col-md-4 not-onphone unlock-icon">
                                <i className="bi bi-unlock-fill"></i>
                            </div>
                            <div className="col-12 col-md-8">
                                <h4>Request Access</h4>
                                <p>In order to login to <strong>orbit-pwa</strong> you must provide some details to create an account<br />
                                    When your account has been authorised, you will receive an SMS message notifying you that you are now able to login</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form">Full Name</div>
                    </div>
                    <div className="col-md-4 col-12">
                        <input className="form-control" onChange={(e) => updateFullName(e)} placeholder="your full name" />
                    </div>
                </div>
                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form">User Name</div>
                    </div>
                    <div className="col-md-4 col-12">
                        <input className="form-control" onChange={(e) => updateUserName(e)} placeholder="create a user name" />
                    </div>
                </div>
                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form">Password</div>
                    </div>
                    <div className="col-md-4 col-12">
                        <input className="form-control" onChange={(e) => updatePassword(e)} type="password" placeholder="create a password" />
                    </div>
                </div>
                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form">Email</div>
                    </div>
                    <div className="col-md-4 col-12">
                        <input className="form-control" type="email" onChange={(e) => updateEmail(e)} placeholder="email" />
                    </div>
                </div>
                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form">Phone</div>
                    </div>
                    <div className="col-md-4 col-12">
                        <input className="form-control" type="phone" onChange={(e) => updatePhone(e)} placeholder="phone number (incl country code)" />
                    </div>
                </div>

                <div className="row justify-content-center orbit-row-data">
                    <div className="col-md-2 col-12">
                        <div className="label-form"></div>
                    </div>
                    <div className="col-md-4 col-12">
                        <button type="button" className="btn btn-light" onClick={() => handleCancelButton()}>Cancel <i className="bi bi-x"></i></button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-primary" disabled={isSaving} onClick={() => saveuser()} >Request <i className="bi bi-arrow-right-circle"></i> </button>
                    </div>
                </div>
            </div>
                </Container>

            )
    }

    function pageContents() {

        if (thanks) {
            return thanksBody();
        }
        else {
            return requestBody();
        }

    }

    return (

        <>
            

                        <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />



            <div className="pageHeader">
                {pageContents()}
            </div>
        </>

    );

}

export default RequestAccessControl;