import React, { Component } from 'react';
import { Route, Routes, useNavigate, Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/ui/layout/Layout';
import './custom.css';
import { Splash } from './pages/Splash'
import { ReadLocal } from 'utils/LocalStorageHelper'
import DataSync from 'utils/DataSync'
import  AnimatedRoutes from 'components/AnimatedRoutes'

class App extends Component {
  static displayName = App.name;



    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {

        console.log('%cOrbit', 'color: white; background: black; font-size: 30px');
        console.log('%cIf you are reading this, you are probably either looking at something thats broken or trying to hack... hope its the first option!', 'color: #aaa; background: white; font-size: 20px');
        console.log('%cI like your style, if you want to ask some questions - feel free to email me (steve) at nenorse@gmail.com. I would be happy to explain some of the technology used here if you are interested. :)   ', 'color: black; background: white; font-size: 12px');

        setTimeout(
            () => {
                this.setState({ loading: false });
                this.defaultLoginCheck();
            },
            global.config.USE_SPLASH ? global.config.SPLASH_DELAY : 50
        );
    }

    defaultLoginCheck() {
        let login = ReadLocal(global.config.LOGIN_KEY);
        if (login) {
            this.props.navHook('/dashboard')
        }
    }

    splashLayout() {

        const content = global.config.USE_SPLASH ? <Splash /> : <></>

        return (
            <>{content}</>
        );
    }

    animatedRoutesLayout() {
        return (
            <>
                <DataSync />
                <Layout>
                    <AnimatedRoutes />
                </Layout >
            </>
        );

    }


    routerLayout() {
        return (
            <>
                <DataSync/>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
                </Layout >
            </>
        );
    }

    render() {


        let content = this.animatedRoutesLayout();
        if (this.state.loading) {
            content = this.splashLayout();
        }

        return (
            <div>{content}</div>
            );

      }
}


function navParams(Component) {
    return props => <Component navHook={useNavigate()} />;
}


export default navParams(App);
