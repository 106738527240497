import * as React from 'react';
import { Modal, Button } from 'react-bootstrap'
import { DataHelper } from 'utils/DataHelper';
import { db } from 'utils/db'

export function ReceptionRow(props) {

    let exhibitor = JSON.parse(props.item.contents);
    

    function toggleCheckIn() {
        const checkInDate = exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;
        DataHelper.upload(exhibitor, db.exhibitors, exhibitor.exhibitorId, "exhibitor");
    }

    return (
        <div className={exhibitor.checkInDate ? "row receptionRow checkedIn" : "row receptionRow"} key={exhibitor.tableGuid} >
            <div className="col-md-12">
                <div className="rebookCheckbox">
                    <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                    <div className="rebookNumberCheck">
                        {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>}
                        {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                    </div>
                </div>
                <div className="rebookDetails">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}



/*
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';


export class ReceptionRow extends Component {

    constructor(props) {
        super(props);

        this.state = { exhibitor: exhibitor };
        toggleCheckIn = toggleCheckIn.bind(this);
    }


    toggleCheckIn() {
        const exhibitor = this.state.exhibitor;
        const checkInDate = this.state.exhibitor.checkInDate ? null : new Date().toISOString();
        exhibitor.checkInDate = checkInDate;    //? :|||
        this.setState({ exhibitor: exhibitor })

        //-- save the change
        this.props.updateExhibitor(exhibitor);

    }



    render() {
        const exhibitor = this.state.exhibitor;

        return (
            <div className={exhibitor.checkedIn ? "row receptionRow checkedIn" : "row receptionRow"} key={ exhibitor.tableGuid } >
                <div className="col-md-12">
                    <div className="rebookCheckbox">
                        <div className="rebookNumber"><span className="rebookText">{exhibitor.rebookNumber}</span></div>
                        <div className="rebookNumberCheck">
                            {exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-check-square" onClick={toggleCheckIn}></i></Button>  }
                            {!exhibitor.checkInDate && <Button variant="light" className="unchecked"><i className="bi bi-square" onClick={toggleCheckIn}></i></Button>}
                        </div>
                    </div>
                    <div className="rebookDetails">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="exhibitorName">{exhibitor.exhibitorName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 exhibitorTime">12:30pm Today  <span style={{ marginLeft: "20px", fontWeight: "500" }}>Booth: {exhibitor.standNumber}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/