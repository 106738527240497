import React, { Component } from 'react';
import  NavMenu  from './../nav/NavMenu';
import IdleTimer from 'react-idle-timer'
import { Splash } from 'pages/Splash'
import { NetworkStatus } from 'utils/NetworkStatus'
import FooterMenu  from './../nav/FooterMenu';



export class Layout extends Component {
  static displayName = Layout.name;

    constructor(props) {
        super(props)

        this.state = {
            timeout: 1000 * 180 * 1,
            showSplash: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer =null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }


    _onAction(e) {
        this.setState({ isTimedOut: false })
    }

    _onActive(e) {
        this.setState({ isTimedOut: false })
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut
        if (!isTimedOut) {
            this.setState({ showSplash: true })
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }

    }

    handleClose() {
        this.setState({ showSplash: false })
    }

    handleLogout() {
        this.setState({ showSplash: false })
        this.props.history.push('/')
    }

    displayNavMenu() {
        return (
            <div>
                <NavMenu />

                    {this.props.children}

                <NetworkStatus>
                    {({ online }) => (
                        <div>{!online && <span className="offline-notice badge bg-dark"> offline </span>}</div>
                    )}
                </NetworkStatus>
                <FooterMenu/>
                      </div >
            )
    }

    displaySplash() {
        return(
            <Splash/>
            )
    }

    render() {

        let content = this.state.isTimedOut ? this.displaySplash() : this.displayNavMenu();

      return (
          <>
              <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={this.state.timeout} />
             
              {content}
              </>
    );
  }
}
