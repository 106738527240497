import React, { Component, useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "utils/db";
import PageHeader from 'components/form/PageHeader'
import { ReceptionRow } from 'components/ui/rows/ReceptionRow'
import { ReceptionRowFilter } from 'components/ui/rows/ReceptionRowFilter'
import { DataHelper } from 'utils/DataHelper';
import { Container } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { motion } from 'framer-motion'
import  PageSpinner  from 'components/ui/spinner/spinner'

export function Reception() {

    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const exhibitors = useLiveQuery(() => db.exhibitors.toArray());

    if (!exhibitors) return <><PageSpinner /></>;

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        const filteredData = [];

        if (searchValue !== '') {
            for (let i = 0; i < exhibitors.length; i++) {
                const item = JSON.parse(exhibitors[i].contents);
                const exhibitor = exhibitors[i];

                if (item.exhibitorName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.standNumber.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                    filteredData.push(exhibitor);
                }

            }
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults([])
        }

    }

    return (
        <Container>
        <motion.div
            className="page-header-section"
        >

            <PageHeader pageName={"Reception"} showTime={true} />
            <div className="row" style={{marginBottom:"16px"} }>
            <div className="input-group-filter col-md-12">
                <input type="text" className="form-control" placeholder="Search for..." aria-label="Filter" aria-describedby="basic-addon1" onChange={(e) => searchItems(e.target.value)} />
                </div>
            </div>

            {searchInput.length > 0 ? (

                filteredResults.map(item => (
                    <ReceptionRowFilter item={item} key={item.id} />
                ))

            ) : (
                    exhibitors.map(item => (
                        <ReceptionRow item={item} key={item.id} />
                    ))
            )
            }
            </motion.div>
        </Container>
    );

}



