//-- This component is used to sync data between the application and the indexdb database.
//-- it does this via a timer?
import Dexie from 'dexie'
import React, { Component, useState } from 'react';
import { db } from './db';
import { DataHelper } from './DataHelper'
import { LoggedIn, ReadLocal, WriteLocal, GetLastSyncDate } from 'utils/LocalStorageHelper'

class DataSync extends React.Component {

    static finishedDownload = false;    //-- hack hack hack

    constructor(props) {
        super(props);
        this.state = {
            isEnabled: true,
            isUploading: false,
            isDownloading: false,
            syncingEntity: '',
            uploadTimer: {},
            downloadTimer: {}
        };
    }

    async checkUploadComplete() {
        const listing = await db.uploadsWaiting();
        if (listing.length == 0 && this.state.isUploading) {
            this.finishUpload();
        }
    }



    async syncCheck() {


        if (DataSync.finishedDownload) {
            this.setState({ isDownloading: false });
        }


        //-- we can't sync if we are online or already syncing..
        if (!navigator.onLine || this.state.isUploading || this.state.isDownloading) return;

        const listing = await db.uploadsWaiting();

        //-- first of all process any uploads that are waiting.
        if (listing.length > 0) {

            DataHelper.uploadWaiting()
            this.startUpload();
        }
        else {
            const login = LoggedIn();

            if (login) {
                this.startDownload();
                await DataHelper.downloadWaiting(this.finishDownload);
            }
        }

    }

    startDownload() {
        this.setState({ isDownloading: true });
    }

    finishDownload() {
        DataSync.finishedDownload = true;
    }

    startUpload() {
        const uploadTimer = setInterval(() => { this.checkUploadComplete(); }, 1000);
        this.setState({ isUploading: true, uploadTimer: uploadTimer });
    }

    finishUpload() {
        const uploadTimer = this.state.uploadTimer; 
        clearInterval(uploadTimer);
        this.setState({ isUploading: false, uploadTimer : null});
    }

    componentDidMount() {
        if (this.state.isEnabled) {
            setInterval(() => { this.syncCheck(); }, global.config.SYNC_FREQUENCY_SECS * 1000);
        }
    }

    syncingIconUp() {
        return (
            <>
                <div className="sync-notify-cloud">
                    <i className="bi bi-cloud-arrow-up"></i>
                </div>
            </>
        )
    }

    syncingIconDown() {
        return (
            <>
                <div className="sync-notify-cloud">
                    <i className="bi bi-heart"></i>
                </div>
            </>
        )
    }


    syncingIconAnimated(message) {
        return (
            <>
                <div className="sync-notify">
                    <span className="badge bg-primary pad-badge">{message}</span>
                </div>

                <div className="sync-spinner">
                    <i className="notify-spinner bi bi-arrow-repeat"></i>
                </div>
            </>
        )
    }

    notSyncingIcon() {
        return (
            <></>
        )
    }

    render() {

        let content = this.notSyncingIcon();
        if (this.state.isDownloading) content = this.syncingIconDown();
        if (this.state.isUploading) content = this.syncingIconUp();

        return (
            <>
                {content}
            </>
        )

    }
}

export default DataSync;